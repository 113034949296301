import DatasetResult from "./DatasetResult";

const MatchingDatasets = ({
  searchResults: { hits = [] } = {},
  filters = [],
  actions = {},
}) => {
  const { onVectorClick: onClick } = actions;

  const matches = filters.length
    ? hits.filter((dataset) => {
        return filters.some(({ key, value }) => dataset[key] === value);
      })
    : hits;

  return (
    <>
      {matches.map((dataset) => {
        return (
          <DatasetResult
            key={dataset.dataset_key}
            dataset={dataset}
            onClick={onClick}
          />
        );
      })}
    </>
  );
};

export default MatchingDatasets;
