import cleanRowValues from "./cleanRowValues/code";
import removeEmptyRows from "./removeEmptyRows/code";

const beautifyMashup = ({ mashup = {} } = {}) => {
  if (!mashup) return undefined;
  if (!mashup.rows?.length) return mashup;

  var rows = [...mashup.rows];

  rows = cleanRowValues({ rows });
  rows = removeEmptyRows({ rows });

  return {
    ...mashup,
    rows,
  };
};

export default beautifyMashup;
