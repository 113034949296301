export default function Tooltipper({ tip, children }) {
  if (!tip) return <div>{children}</div>;
  
  return (
    <div className="group relative flex">
      {children}
      <span className="absolute min-w-[300px] top-2 z-50 mt-8 scale-0 rounded bg-zinc-600 px-4 py-2 text-sm text-white group-hover:scale-100">
        {tip}
      </span>
    </div>
  );
}
