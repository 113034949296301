import Tooltipper from "../Tooltipper";

const NewTabLink = ({ href, tip = "", children, icon = <></> }) => {
  return (
    <Tooltipper tip={tip}>
      <div className="flex flex-row gap-2 justify-start items-center">
        <a
          href={href}
          target="_blank"
          rel="noreferrer"
          className="no-underline text-blue-900 hover:text-blue-800 dark:text-primary-300 dark:hover:text-primary-200 hover:font-semibold"
        >
          {children}
        </a>
        {icon}
      </div>
    </Tooltipper>
  );
};

export default NewTabLink;
