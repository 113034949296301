const convertDataset = (data) => {
  if (!data) return undefined;

  const {
     tag_category: category, dataset_key, description, publisher_name: name, publisher_api: api, pub_ticker: ticker, publisher_ticker,
  } = data;

  return {
    dataset_key,
    description,
    category,
    ticker: ticker || publisher_ticker,
    publisher: {
      name,
      api,
    },
  };
};

export default convertDataset;
