import { useContext } from "react";

import DatabaseContext from "../../../database/DatabaseContext";
import MashupApi from "./MashupApi";
import MashupContext from "./MashupContext";

const MashupProvider = ({ children }) => {
  const db = useContext(DatabaseContext);
  const api = new MashupApi(db);
  
  return (
    <MashupContext.Provider value={api}>{children}</MashupContext.Provider>
  );
};

export default MashupProvider;
