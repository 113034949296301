import LabelledTextInput from "./LabelledTextInput";

const SearchCriteriaInput = ({ criteria = "", actions = {} }) => {
  const { onCriteriaChange, onCriteriaSubmit } = actions;

  const handleKeyDown = ({ code = "" } = {}) => {
    if (/enter/i.test(code)) {
      onCriteriaSubmit();
    }
  };

  return (
    <LabelledTextInput
      id="e02fda71-b9a1-48e4-82c9-6f195a312a6f"
      label="Search"
      placeholder="e.g. gdp health total"
      onChange={onCriteriaChange}
      onKeyDown={handleKeyDown}
      value={criteria}
    />
  );
};

export default SearchCriteriaInput;
