import ReactDOM from "react-dom/client";

import { RecoilRoot } from "recoil";
import theme from "./_app/theme/material";
import { ThemeProvider } from "@emotion/react";

import AppRouter from "./_app/routes/AppRouter";
import AuthProvider from "./_services/auth/AuthProvider";
import CacheProvider from "./_services/cache/CacheProvider";
import DatabaseProvider from "./_services/database/DatabaseProvider";
import DataSourceProvider from "./_services/sources/DataSourceProvider";
import FileStorageProvider from "./_services/cloud/FileStorageProvider";
import LoggingProvider from "./_services/logging/LoggingProvider";
import MashupProvider from "./_services/factivated/mashups/api/MashupProvider";
import PublicationProvider from "./_services/factivated/publication/PublicationProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <RecoilRoot>
      <LoggingProvider>
        <CacheProvider>
          <AuthProvider>
            <DatabaseProvider>
              <FileStorageProvider>
                <MashupProvider>
                  <PublicationProvider>
                    <DataSourceProvider>
                      <AppRouter />
                    </DataSourceProvider>
                  </PublicationProvider>
                </MashupProvider>
              </FileStorageProvider>
            </DatabaseProvider>
          </AuthProvider>
        </CacheProvider>
      </LoggingProvider>
    </RecoilRoot>
  </ThemeProvider>
);
