export default function abbreviate({
    text = "",
    maxWords = 25
  }) {
    const words = text.split(/\s+/);
    const preamble = words.slice(0, maxWords).join(" ");
    const more = words.length <= maxWords ? "" : " ...";
  
    return {
        preamble,
        more,
        text: `${preamble} ${more}`
    };
  }