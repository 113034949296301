import AppHeaderBar from "../../_app/components/menus/AppHeaderBar";
import FullPageContainer from "../../_app/components/containers/FullPageContainer";
import { LeftPanel, RightPanel, SplitPanel } from "../../_app/components/containers/SplitPanel";
import PublishedMashupList from "./published/PublishedMashupList";
import PageDebugInfo from "../../_common/debug/PageDebugInfo";
import WaitingSpinner from "../../_app/components/feedback/WaitingSpinner";
import PrivateMashupList from "./private/PrivateMashupList";

const UserMashupsView = ({ publications, mashups, actions = {} }) => {
  return (
    <FullPageContainer>
      <AppHeaderBar />
      {!Boolean(publications) && <WaitingSpinner />}
      <SplitPanel>
        <LeftPanel width={50}><PrivateMashupList mashups={mashups} actions={actions} /></LeftPanel>
        <RightPanel width={50}><PublishedMashupList mashups={publications} actions={actions} /></RightPanel>
      </SplitPanel>
      
      <PageDebugInfo
        sid="32f1107b-db44-49ed-a780-3f45d1feee06"
        data={{ publications, mashups }}
      />
    </FullPageContainer>
  );
};

export default UserMashupsView;
