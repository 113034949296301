const ResetConfirmationPage = ({ status: { email } = {} }) => {
  const message = `We've sent a password reset email to ${email}`;

  return (
    <div
      style={{
        backgroundImage: "url(/images/data.jpg)",
        backgroundSize: "cover",
      }}
      className="flex flex-col justify-center items-center h-screen"
    >
      <div className="max-w-lg p-4 rounded-xl text-lg shadow-lg bg-cta-700 text-zinc-300 border md:min-w-[500px]">
        {message}
      </div>
    </div>
  );
};

export default ResetConfirmationPage;
