import copyToClipboard from "../../../_common/lib/copyToClipboard";
import SimpleIconButton from "./SimpleIconButton";

const CopyButton = ({
  content,
  tip = "Copy to clipboard",
}) => {
  const handleClick = () => {
    copyToClipboard(
      typeof content === "string" ? content : JSON.stringify(content)
    );
  };

  return (
    <SimpleIconButton
      icon="copy"
      onClick={handleClick}
      tip={tip}
    />
  );
};

export default CopyButton;
