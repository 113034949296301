import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faArrowDown,
  faArrowUp,
  faArrowsRotate,
  faBug,
  faChartLine,
  faCircleQuestion,
  faCloudArrowUp,
  faCopy,
  faDatabase,
  faDownLeftAndUpRightToCenter,
  faEraser,
  faExpand,
  faFloppyDisk,
  faGear,
  faSearch,
  faTable,
  faPenToSquare,
  faShareNodes,
  faTrashCan,
  faUpRightFromSquare,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

const icons = {
  "arrow-down": faArrowDown,
  "arrow-up": faArrowUp,
  "cloud-arrow-up": faCloudArrowUp,
  "chart-line": faChartLine,
  collapse: faDownLeftAndUpRightToCenter,
  copy: faCopy,
  database: faDatabase,
  edit: faPenToSquare,
  eraser: faEraser,
  expand: faExpand,
  gear: faGear,
  grid: faTable,
  help: faCircleQuestion,
  "open-new-tab": faUpRightFromSquare,
  publish: faShareNodes,
  refresh: faArrowsRotate,
  save: faFloppyDisk,
  search: faSearch,
  share: faShareNodes,
  trash: faTrashCan,
  user: faUser,
};

const colours = ["primary", "danger", "accent"];

export default function AppIcon({ iconKey, colour = "primary" }) {
  const icon = icons[iconKey] || faBug;

  if (!colours.includes(colour) || icon === faBug) {
    return (
      <FontAwesomeIcon
        icon={icon}
        className="text-lime-500 text-lg animate-pulse"
      />
    );
  }

  if (colour === "primary") {
    return (
      <FontAwesomeIcon
        icon={icon}
        className="text-primary-500  hover:text-primary-400 dark:text-primary-300 dark:hover:text-primary-200 text-lg"
      />
    );
  }

  if (colour === "danger") {
    return (
      <FontAwesomeIcon
        icon={icon}
        className="text-cta-600 hover:text-cta-400 dark:text-cta-500 dark:hover:text-cta-300 text-lg"
      />
    );
  }

  if (colour === "accent") {
    return (
      <FontAwesomeIcon
        icon={icon}
        className="text-cta-400 hover:text-cta-600 dark:text-cta-400 dark:hover:text-cta-600 text-lg"
      />
    );
  }
}

const UnstyledAppIcon = ({ iconKey }) => {
  return <FontAwesomeIcon icon={icons[iconKey] || faBug} />;
};

export {
  UnstyledAppIcon
}