import { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormButton from "./FormButton";
import FormTitle from "../ui/FormTitle";
import { faGithub, faGoogle } from "@fortawesome/free-brands-svg-icons";
import FormInputLabel from "../ui/FormInputLabel";

const LoginForm = ({
  onEmailSignIn,
  onForgotPasswordClick,
  onGithubLogin,
  onGoogleLogin,
  allowSocialSignIn,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleForgotPasswordClick = () => {
    onForgotPasswordClick(email);
  };

  const handleEmailSignIn = () => {
    onEmailSignIn({
      email, password
    })
  }

  return (
    <div className="flex flex-col gap-2 max-w-lg p-4 rounded-xl shadow-lg bg-zinc-50 dark:bg-dark-500 dark:text-zinc-500 border dark:border-zinc-700 md:min-w-[500px]">
      <FormTitle title="Welcome back" />
      {allowSocialSignIn && (
        <div className="flex justify-stretch gap-2">
          <FormButton onClick={onGoogleLogin}>
            <span className="text-red-500">
              <FontAwesomeIcon icon={faGoogle} />
            </span>
            Log in with Google
          </FormButton>
          <FormButton onClick={onGithubLogin}>
            <span className="text-indigo-600 dark:text-indigo-400">
              <FontAwesomeIcon icon={faGithub} />
            </span>
            Log in with Github
          </FormButton>
        </div>
      )}
      {allowSocialSignIn && (
        <div className="relative flex py-5 items-center">
          <div className="flex-grow border-t border-zinc-400"></div>
          <span className="flex-shrink mx-4 text-zinc-400">or</span>
          <div className="flex-grow border-t border-zinc-400"></div>
        </div>
      )}
      <div className="flex flex-col">
        <FormInputLabel title="Email" />
        <input
          type="text"
          className="grow mt-2 mb-4 rounded-md px-2 py-2 bg-zinc-100 dark:bg-zinc-300 border"
          onChange={({ target: { value = "" } }) => setEmail(value)}
          placeholder="someone@somewhere.com"
          value={email}
        />
        <FormInputLabel title="Password" />
        <input
          type="password"
          className="grow mt-2 rounded-md px-2 py-2 bg-zinc-100 dark:bg-zinc-300 border"
          onChange={({ target: { value = "" } }) => setPassword(value)}
          placeholder=""
          value={password}
        />
      </div>
      <div className="flex flex-row justify-between mt-6">
        <div>
          <div className="flex flex-row items-center gap-2">
            <input type="checkbox" />
            <div className="text-zinc-500 dark:text-zinc-400">Remember me</div>
          </div>
        </div>
        <div
          onClick={handleForgotPasswordClick}
          className="text-sky-600 dark:text-sky-400 font-semibold cursor-pointer"
        >
          Forgot password?
        </div>
      </div>
      <div className="mt-6 flex">
        <button
          onClick={handleEmailSignIn}
          className="cursor-pointer bg-secondary-700 dark:bg-secondary-500 dark:text-zinc-900 text-zinc-50 rounded-md px-4 py-2 flex-grow hover:shadow-lg"
        >
          Sign in to Factivated
        </button>
      </div>
      <div
        onClick={() => alert("click!")}
        className="mt-6 flex gap-2 text-zinc-500 dark:text-zinc-400"
      >
        Don't have an account yet?{" "}
        <span className="text-sky-600 dark:text-sky-400 font-semibold cursor-pointer">
          Sign up here
        </span>
      </div>
    </div>
  );
};

export default LoginForm;
