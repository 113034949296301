export default function TextButton({ text, onClick = () => {} }) {
  return (
    <div
      onClick={onClick}
      className="border rounded-md px-2 py-1 text-sm border-zinc-400 cursor-pointer hover:bg-zinc-100 dark:hover:bg-zinc-700 dark:text-primary-400"
    >
      {text}
    </div>
  );
}
