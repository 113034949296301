const getVectorDescription = ({ datasets = [], vector = {} } = {}) => {
  const { dataset_key, description = "" } = vector;

  const { publisher = {} } =
    datasets.find((item) => item.dataset_key === dataset_key) || {};

  return `Source: ${publisher.name} / ${description}`;
};

export default getVectorDescription;
