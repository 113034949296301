import getMashupRows from "../datasets/getMashupRows";
import identify from "../../../_common/lib/identify";
import lastElement from "../../../_common/lib/arrays/lastElement";
import removeFields from "../../../_common/lib/removeFields";
import clog from "../../../_common/lib/debug/clog";

const rootPath = "mashups";

const getDocumentPath = (fullPath = "") => {
  const segments = fullPath.split("/");

  return {
    fullPath,
    path: segments.slice(0, segments.length - 1).join("/"),
    id: lastElement(segments),
  };
};

function PublicationApi({
  addDocument,
  eraseDocument,
  getDocument,
  subscribeDocument,
  subscribeDocs,
  uid,
}) {
  const mashupPath = `${rootPath}/${uid}/published`;

  this.publishMashup = ({ mashup, frozen, sid }) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (!mashup) throw new Error(`mashup is undefined`);

        const { id = identify() } = mashup;

        const data = frozen ? mashup : removeFields(mashup, "dirty");

        data.view = data.view === "settings" ? "table" : data.view;

        const result = await addDocument({
          path: mashupPath,
          id,
          data,
          sid,
        });

        // save the full doc path so we don't have to expose it publicly - a bit like a tiny url
        await addDocument({
          path: "paths",
          id: id,
          data: { fullPath: `${mashupPath}/${id}` },
        });

        resolve(result);
      } catch (error) {
        reject(error);
      }
    });
  };

  this.subscribePublished = ({ hook, sid }) => {
    return subscribeDocs({ path: mashupPath, hook, sid });
  };

  this.getDocumentAddress = ({ id, sid }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { fullPath } =
          (await getDocument({ path: `paths/${id}`, sid })) || {};

        resolve(getDocumentPath(fullPath));
      } catch (error) {
        reject(error);
      }
    });
  };

  this.subscribeMashup = ({ id, hook, sid }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const address = await this.getDocumentAddress({ id, sid });

        const cancel = subscribeDocument({
          path: address.path,
          id,
          hook,
          sid,
        });

        resolve(cancel);
      } catch (error) {
        reject(error);
      }
    });
  };

  this.getMashup = ({ id, sid }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { fullPath } =
          (await getDocument({ path: `paths/${id}`, sid })) || {};

        if (fullPath) {
          const manifest = await getDocument({ path: fullPath, sid });
          resolve(manifest);
        } else {
          throw new Error(`full path for ${id} not found`);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  this.getMashupData = ({ id, sid }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const manifest = await this.getMashup({ id, sid });

        if (!manifest) throw new Error(`manifest ${id} not found`);

        if (!manifest.rows) {
          const rows = await getMashupRows({ manifest, sid });
          resolve({ ...manifest, rows });
        } else {
          resolve(manifest);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  this.deletePublishedMashup = ({ mashup = {}, sid }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { id } = mashup;
        const { fullPath } = (await this.getDocumentAddress({ id, sid })) || {};

        clog("deleteMashup", fullPath);

        if (fullPath) {
          await eraseDocument({
            path: fullPath,
            sid,
          });

          await eraseDocument({ path: `paths/${id}`, sid });

          resolve(true);
        } else {
          resolve(undefined);
        }
      } catch (error) {
        reject(error);
      }
    });
  };
}

export default PublicationApi;
