const convertVector = (dataset, vector) => {
  if (!dataset || !vector) return undefined;

  const { key: dataset_key, description, series = [] } = dataset;

  const { name, filters } = vector;

  return {
    vector_id: `${dataset_key}.${name}`,
    dataset_key,
    description: `${description} ${
      series.length <= 1 ? "" : vector.description
    }`.trim(),
    name,
    filters
  };
};

export default convertVector;
