// Import the functions you need from the SDKs you need
import { initializeApp } from "@firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyA6YYhKum2aerT0cqAITgwAssNuaBvzjq8",
  authDomain: "refactivated.firebaseapp.com",
  projectId: "refactivated",
  storageBucket: "refactivated.appspot.com",
  messagingSenderId: "113607309204",
  appId: "1:113607309204:web:b97be5b513ac1e06f60181",
  measurementId: "G-Y416X68B1M",
};

const firebaseApp = initializeApp(firebaseConfig);
const firestore = getFirestore(firebaseApp);

export { firebaseApp, firestore };
