import { useContext } from "react";

import DataSourceContext from "../_services/sources/DataSourceContext";
import DataSourceListView from "./DataSourceListView";
import QueryDataProvider from "../_common/query/QueryDataProvider";

const DataSourceListPage = () => {
  const { getDataSources } = useContext(DataSourceContext);
  
  return (
    <QueryDataProvider
      query={getDataSources}
      view={<DataSourceListView sid="f87d7a6a-5afc-4d96-a516-a617aa200d4d" />}
    />
  );
};

export default DataSourceListPage;
