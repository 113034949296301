import { useContext } from "react";

import { Divider } from "@mui/material";

import AppLogo from "../../../home/AppLogo";
import AuthContext from "../../../_services/auth/AuthContext";
import ContextMenu from "./ContextMenu";
import CtaLinkButton from "../buttons/CtaLinkButton";
import navigateTo from "../../../_common/lib/navigateTo";
import SignOutButton from "../../auth/signout/SignOutButton";
import UserAvatarGroup from "../../auth/user/UserAvatarGroup";

const AppHeaderBar = ({ onClick }) => {
  const { authenticatedUser } = useContext(AuthContext) || {};

  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-row justify-between items-center">
        <div className="">
          <a className="block max-w-max" href="/">
            <AppLogo />
          </a>
        </div>
        {authenticatedUser && <UserAvatarGroup />}
        {authenticatedUser ? (
          <SignOutButton onLogout={() => navigateTo("/signin")} />
        ) : (
          <CtaLinkButton href="/signin">Sign In</CtaLinkButton>
        )}
      </div>
      <ContextMenu onClick={onClick} />
      <Divider style={{ marginBottom: "2rem" }} />
    </div>
  );
};

export default AppHeaderBar;
