import abbreviate from "../../../_common/lib/text/abbreviate";

export default function IntroText({ text = "", maxWords = 25, hideEllipsis }) {
  const { preamble, more } = abbreviate({ text, maxWords });

  return (
    <div className="text-sm">
      {preamble}
      <span className="font-bold text-primary-500">{hideEllipsis ? "" : more}</span>
    </div>
  );
}
