const uniquely = (items = [], key) => {
  if (items.length === 0) return [];
  if (typeof items[0] === "string") {
    return Array.from(new Set(items));
  }

  if (typeof items[0] === "object") {
    // get unique set of keys
    const values = Array.from(new Set(items.map((item) => item[key])));
    // return first match for each key
    return values.map((value) => items.find((item) => item[key] === value));
  }

  return undefined;
};

export default uniquely;
