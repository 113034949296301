import { useState } from "react";

import { useRecoilValue } from "recoil";
import { Backdrop } from "@mui/material";

import AppHeaderBar from "../_app/components/menus/AppHeaderBar";
import DatasetViewContainer from "./datasets/DatasetViewContainer";
import expandedRightPanelAtom from "../_common/recoil/workspace/expandedRightPanelAtom";
import FilterTags from "../search/results/FilterTags";
import FullPageContainer from "../_app/components/containers/FullPageContainer";
import {
  LeftPanel,
  RightPanel,
  SplitPanel,
} from "../_app/components/containers/SplitPanel";
import Loading from "../_app/components/loading/Loading";
import MatchingDatasets from "../search/results/MatchingDatasets";
import MessageAlert from "../_app/components/feedback/MessageAlert";
import PageDebugInfo from "../_common/debug/PageDebugInfo";
import PublisherTags from "../search/results/PublisherTags";
import ResultTags from "../search/results/ResultTags";
import SearchCriteriaInput from "../search/SearchCriteriaInput";

const MashupView = ({
  busy,
  filters = [],
  mashup = {},
  mashupActions,
  searchActions,
  message,
  onAppMenuClick,
  onMessageClose,
  publisherIndex,
  searchResults,
  sources = [],
  user = {},
}) => {
  const [filterViewOn, setFilterViewOn] = useState(false);
  const expandedRightPanel = useRecoilValue(expandedRightPanelAtom);

  return (
    <FullPageContainer>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={busy.value}
      >
        <Loading />
      </Backdrop>
      {message && <MessageAlert message={message} onClose={onMessageClose} />}
      <AppHeaderBar onClick={onAppMenuClick} />
      <SplitPanel>
        <LeftPanel hidden={expandedRightPanel}>
          <div className="flex flex-col gap-6">
            <SearchCriteriaInput
              criteria={mashup.criteria}
              actions={searchActions}
            />
            {searchResults?.hits?.length === 0 && (
              <div
                className="text-xl text-white bg-cta-500 px-4 py-2 rounded-md"
                variant="h6"
                color="secondary"
              >
                Sorry - we couldn't find any datasets that match your search
              </div>
            )}
            {searchResults?.hits?.length && (
              <div className="flex flex-row gap-4 text-center">
                <div
                  className="border border-zinc-400 dark:bg-transparent  bg-primary-400 hover:bg-cta-500 hover:font-semibold text-white rounded-md cursor-pointer px-4 py-2 flex-grow"
                  onClick={() => setFilterViewOn(!filterViewOn)}
                >
                  {filterViewOn ? "Hide" : "Show"} Dataset Filters
                </div>
              </div>
            )}

            <MatchingDatasets
              filters={filters}
              actions={mashupActions}
              searchResults={searchResults}
            />
          </div>
        </LeftPanel>
        <RightPanel expanded={expandedRightPanel}>
          {!filterViewOn && (
            <DatasetViewContainer
              mashup={mashup}
              mashupActions={mashupActions}
            />
          )}
          {filterViewOn && (
            <div className="flex flex-col gap-4">
              <div className="text-xl text-primary-500 dark:text-primary-300">
                Dataset Filters
              </div>
              <div className="text-lg text-primary-500 dark:text-primary-200">
                Publishers / Data Sources
              </div>
              <PublisherTags
                publisher_tags={searchResults?.pub_buckets}
                publishers={sources}
                selected={publisherIndex}
                actions={searchActions}
              />
              <div className="text-lg text-primary-500 dark:text-primary-200">
                Categories / Subjects
              </div>

              <FilterTags
                tag_subjects={searchResults?.tag_subjects}
                actions={searchActions}
              />
              <div className="text-lg text-primary-500 dark:text-primary-200">
                Countries and Geopolitical Entities
              </div>

              <ResultTags
                tags={searchResults?.result_tags}
                actions={searchActions}
                selected={filters}
              />
            </div>
          )}
        </RightPanel>
      </SplitPanel>
      <PageDebugInfo
        sid="7f613848-943d-49f9-ab86-0486c9652347"
        data={mashup}
      />
    </FullPageContainer>
  );
};

export default MashupView;
