import clog from "../../_common/lib/debug/clog";
import getVectorDescription from "../../_services/factivated/datasets/getVectorDescription";
import getZoomFilter from "../../_services/factivated/mashups/lib/getZoomFilter/code";
import GridView from "../../_app/components/tables/GridView";
import sortRows from "../../_services/factivated/mashups/lib/sortRows/code";
import ZoomSlider from "../tools/ZoomSlider";

const DatasetTable = ({ mashup = {}, actions = {} }) => {
  const {
    rows = [],
    vectors = [],
    datasets = [],
    period = "",
    sortOrder = 1,
  } = mashup;

  const { onChange } = actions;

  clog("DatasetTable", mashup);

  const zoomFilter = getZoomFilter({ mashup });

  if (vectors.length === 0) return <></>;

  const columns = [
    { field: "period", headerName: period },
    ...vectors.map((vector = {}, index) => {
      const { alias, description, vector_id, multiplier = 0 } = vector;
      // the multiplier gets set to zero by the text input if you delete the test
      const adjust = multiplier === 0 ? 1.0 : Number(multiplier);
      return {
        field: vector_id,
        headerName: alias || description,
        tip: getVectorDescription({ datasets, vector }),
        formatter: ({ values }) =>
          values[index] ? (values[index] * adjust).toLocaleString() : "",
      };
    }),
  ];

  return (
    <div className="flex flex-col gap-2">
      <ZoomSlider
        mashup={mashup}
        onChange={(zoom) => onChange({ zoom })}
        valueLabelDisplay="off"
      />
      <GridView
        rows={sortRows({
          rows: rows
            .map((row) => {
              return { ...row, id: row.period };
            })
            .filter(zoomFilter),
          sortOrder,
        })}
        columns={columns}
      />
    </div>
  );
};

export default DatasetTable;
