import { useContext } from "react";

import DatabaseContext from "../../database/DatabaseContext";
import PublicationApi from "./PublicationApi";
import PublicationContext from "./PublicationContext";

const FactivatedProvider = ({ children }) => {
  const db = useContext(DatabaseContext);
  const api = new PublicationApi(db);

  return (
    <PublicationContext.Provider value={api}>
      {children}
    </PublicationContext.Provider>
  );
};

export default FactivatedProvider;
