import axios from "axios";
import getFactivatedData from "./getFactivatedData";
import clog from "../../../_common/lib/debug/clog";

const cloudFunctions = new Map([
  ["nasdaq", "factivated-nasdaq"],
  ["eia-rest-v2", "factivated-eia"],
  ["fred", "factivated-fred"],
  ["fao-fenix", "factivated-fao"],
  ["jodi", "factivated-jodi"],
  ["ons-ts", "factivated-ons"],
  ["quandl", "factivated-quandl"],
  ["landregistry", "factivated-landregistry"],
]);

const getVectorData = ({ dataset, vector }) => {
  const {
    dataset_key,
    publisher: { api },
    ticker,
  } = dataset;

  clog ("getVectorData_A", { dataset, vector, api });

  const gcf = cloudFunctions.get(api.toLowerCase());

  if (gcf) {
    return getFactivatedData({ dataset, vector, gcf });
  }

  // use the old factivated website until everything has migrated to GCF
  return new Promise((resolve, reject) => {
    try {
      const url = `https://www.factivated.com/ws/data/series?ts=0&pub_api=${api}&pub_ticker=${ticker}&vector=${vector.name}&id=${dataset_key}`;

      clog("getVectorData_B", url);

      axios.get(url).then(
        // success
        (response = {}) => {
          if (response.status === 200) {
            var series = { ...response.data };
            series.vector_id = vector.vector_id;
            series.dataset_key = dataset.dataset_key;
            resolve(series);
          } else {
            reject(new Error(`Server returned status: ${response.status}`));
          }
        },
        // failure
        (error) => reject(error)
      );
    } catch (error) {
      reject(error);
    }
  });
};

export default getVectorData;
