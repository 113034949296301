import { useContext, useMemo } from "react";

import FullPageContainer from "../../_app/components/containers/FullPageContainer";
import LoggingContext from "../../_services/logging/LoggingContext";
import PageDebugInfo from "../../_common/debug/PageDebugInfo";

const Page404 = () => {
  const logger = useContext(LoggingContext);
  const { location = {} } = window || {};

  useMemo(() => {
    logger.warn({ pageNotFound: location, sid: 'a643a98c-95ca-4333-9d4f-5351471e6360'});
  }, [location, logger])

  return (
    <FullPageContainer>
        <div className="flex flex-col items-start gap-8">
        <span className="text-7xl text-cta-600 dark:text-cta-300">
          Sorry, Page Not Found / 404
        </span>
        <div className="rounded-md bg-primary-500 text-white dark:bg-primary-400 cursor-pointer px-4 py-2">
          <a href="/">Let's go Home</a>
          </div>
        </div>
      <PageDebugInfo sid="5f9cd580-598c-4bd8-933c-e23767df17cf" data={location} />
    </FullPageContainer>
  );
};

export default Page404;
