import AppHeaderBar from "../_app/components/menus/AppHeaderBar";
import ChartView from "./ChartView";
import FullPageContainer from "../_app/components/containers/FullPageContainer";

const MashupChartView = ({ mashup = {}, actions = {} }) => {
  return (
    <FullPageContainer>
      <div className="flex flex-col gap-4">
        <AppHeaderBar />
        <ChartView mashup={mashup} actions={actions} />
      </div>
    </FullPageContainer>
  );
};

export default MashupChartView;
