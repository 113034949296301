const SplitPanel = ({ children }) => {
  return (
    <div className="flex min-w-full gap-8" >
      {children}
    </div>
  );
};

const LeftPanel = ({ hidden, width = 25, children }) => {
  if (hidden) return <></>;
  return <div style={{ width: `${hidden ? 0 : width}%` }}>{children}</div>;
};

const RightPanel = ({ expanded, width = 75, children }) => {
  return <div style={{ width: `${expanded ? 100 : width}%`, marginLeft: '1rem' }}>{children}</div>;
};

export { LeftPanel, RightPanel, SplitPanel };
