import addMashupVector from "../mashups/lib/addMashupVector";
import createMashup from "../mashups/lib/createMashup";
import getVectorSeries from "./getVectorSeries";

const getMashupRows = ({ manifest: { vectors = [], datasets = [] } }) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (vectors.length === 0) throw new Error(`no vectors in mashup`);
        if (datasets.length === 0) throw new Error(`no datasets in mashup`);

        // initialise the mashup with the first vector
        const mashup = createMashup(
          await getVectorSeries({ vector: vectors[0], datasets })
        );

        const results = await Promise.all(
          vectors.slice(1).map((vector) => {
            return getVectorSeries({ vector, datasets });
          })
        );

        results.forEach(({ dataset, vector, series }) => {
          addMashupVector({ mashup, dataset, vector, series });
        });

        resolve(mashup.rows);
      } catch (error) {
        reject(error);
      }
    });
  };

  export default getMashupRows;