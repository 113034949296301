import { useContext, useState } from "react";

import { sendSignInLinkToEmail } from "firebase/auth";

import { Alert, Button, TextField } from "@mui/material";

import AuthContext from "../../../_services/auth/AuthContext";
import FullPageContainer from "../../../_app/components/containers/FullPageContainer";
import getServerName from "../../../_common/lib/getServerName";
import LoggingContext from "../../../_services/logging/LoggingContext";
import PageDebugInfo from "../../../_common/debug/PageDebugInfo";

const actionCodeSettings = {
  url: `${getServerName()}/login/link`,
  handleCodeInApp: true,
};

const EmailLoginPage = () => {
  const logger = useContext(LoggingContext);
  const { auth } = useContext(AuthContext);
  const [email, setEmail] = useState(window.localStorage.getItem("emailForSignIn") || "");
  const [sent, setSent] = useState(false);
  const [status, setStatus] = useState();

  const sendSignInLink = (email) => {
    return new Promise(async (resolve, reject) => {
      try {
        window.localStorage.setItem("emailForSignIn", email);

        await sendSignInLinkToEmail(auth, email, actionCodeSettings);

        resolve();
      } catch (error) {
        logger.error({ error, sid: "330047f1-396e-473f-b8e4-59a7888e568a" });
        reject(error);
      }
    });
  };

  const onClick = () => {
    sendSignInLink(email)
      .then(() => {
        setSent(true);
        setStatus(undefined);
      })
      .catch((error) => {
        logger.error({ error, sid: "fd76328f-c30c-4fb9-9f2c-3a9a9eec4db2" });
        setSent(false);
        setStatus(`There was a problem sending the link`);
      });
  };

  return (
    <FullPageContainer maxWidth="xs">
      <div className="flex flex-col gap-2">
        <TextField
          value={email}
          onChange={({ target: { value } }) => setEmail(value)}
          type="email"
          placeholder="someone@somewhere.com"
          label="Email"
          required
          id="0fa57119-77d4-4295-b861-624405c470fd"
        />
        {/* <TextField placeholder="jabberwocky ..." label="Password" required /> */}
        <Button onClick={onClick} variant="contained" color="warning" id="7d0a2765-166c-46dc-9e16-b496c4ea3371">
          Log In
        </Button>
        {sent && (
          <Alert severity="success" variant="filled">
            A sign-in link has been sent to your email address
          </Alert>
        )}
        {status && (
          <Alert severity="error" variant="outlined">
            {status}
          </Alert>
        )}
      </div>
      <PageDebugInfo sid='74ebdb4f-3701-468b-bc78-14f72550a112' data={actionCodeSettings} />
    </FullPageContainer>
  );
};

const emailLoginPageUrl = "/login/email";

export default EmailLoginPage;

export {
    emailLoginPageUrl
}
