import { useContext } from "react";

import AuthContext from "../../../_services/auth/AuthContext";
import FileStorageContext from "../../../_services/cloud/FileStorageContext";
import getDelimitedData from "../../../_services/factivated/mashups/lib/getDelimitedData";
import LoggingContext from "../../../_services/logging/LoggingContext";
import MashupContext from "../../../_services/factivated/mashups/api/MashupContext";
import getMashupRows from "../../../_services/factivated/datasets/getMashupRows";
import SimpleIconButton from "../../../_app/components/buttons/SimpleIconButton";

const MashupStorageButton = ({ mashup, wrapper = '"', delimiter = "," }) => {
  const logger = useContext(LoggingContext);
  const { saveFile } = useContext(FileStorageContext) || {};
  const { saveMashup } = useContext(MashupContext) || {};

  const {
    authenticatedUser: { uid },
  } = useContext(AuthContext) || {};

  const handleClick = async () => {
    try {
      const rows = await getMashupRows({
        manifest: mashup,
        sid: "b4a49579-742b-4d71-8f6b-03da4a830ce6",
      });

      const text = getDelimitedData({
        mashup: { ...mashup, rows },
        delimiter,
        wrapper,
      });

      const manifest = await saveFile({
        text,
        path: `mashups/${uid}/${mashup.id}.csv`,
      });

      await saveMashup({
        mashup: { ...mashup, manifest },
        sid: "1470eacb-74de-44b7-a413-54958c709bd2",
      });
    } catch (error) {
      logger.error({ error, sid: "d38d8ae3-01e4-4d7d-a1eb-d0e7c87077f6" });
    }
  };

  return (
    <SimpleIconButton
      icon="cloud-arrow-up"
      onClick={handleClick}
      tip="Create a CSV file in the cloud ⛅"
    />
  );
};

export default MashupStorageButton;
