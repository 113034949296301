import FileStorageContext from "./FileStorageContext";

import { deleteObject, getStorage, ref, uploadBytes, uploadString, getDownloadURL, listAll } from "firebase/storage";

const storage = getStorage();

const saveFile = ({ data, text = "", path = "" }) => {
  return new Promise((resolve, reject) => {
    try {
      const location = ref(storage, path);

      if (text) {
        uploadString(location, text).then((snapshot) => {
          getDownloadURL(snapshot.ref)
            .then((url) => {
              resolve({ url, metadata: snapshot.metadata });
            })
            .catch((error) => {
              reject(error);
            });
        });
      } else {
        uploadBytes(location, data).then((snapshot) => {
          getDownloadURL(snapshot.ref)
            .then((url) => {
              resolve({ url, metadata: snapshot.metadata });
            })
            .catch((error) => {
              reject(error);
            });
        });
      }

    } catch (error) {
      reject(error);
    }
  });
};

const deleteFile = ({ path = "" }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const location = ref(storage, path);
      const result = await deleteObject(location);

      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

const listFiles = ({ path = "" } = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const location = ref(storage, path);
      const { items } = (await listAll(location)) || [];
      const links = await Promise.all(items.map((file) => getDownloadURL(file)));

      console.log("item", items[0]);

      resolve(
        links.map((url, index) => {
          const { name: filename } = items[index];
          const contentType = `image/${filename.split(".").pop()}`.toLowerCase().replace("jpg", "jpeg");

          return { url, filename, contentType };
        })
      );
    } catch (error) {
      reject(error);
    }
  });
};

const listFolders = ({ path = "" } = {}) => {
  return new Promise(async (resolve, reject) => {
    try {
      const location = ref(storage, path);
      const { prefixes } = (await listAll(location)) || [];

      resolve(prefixes);
    } catch (error) {
      reject(error);
    }
  });
};

const api = {
  deleteFile,
  saveFile,
  listFiles,
  listFolders,
};

const FileStorageProvider = ({ children }) => {
  return <FileStorageContext.Provider value={api}>{children}</FileStorageContext.Provider>;
};

export default FileStorageProvider;
