import removeElements from "../../../../../_common/lib/arrays/removeElements";

const removeMashupVector = ({
  mashup = {},
  vector: { vector_id } = {},
} = {}) => {
  const { datasets = {}, vectors = [], rows = [] } = { ...mashup };

  const vectorIndex = vectors.findIndex((v) => v.vector_id === vector_id);

  if (vectorIndex === -1) return mashup;

  const remaining = vectors.filter((_, index) => index !== vectorIndex);

  if (remaining.length > 0) {
    return {
      ...mashup,
      datasets: datasets.filter(ds => remaining.find(({ dataset_key }) => dataset_key === ds.dataset_key)),
      vectors: remaining,
      rows: rows.map((row) => {
        const periods = removeElements(row.periods, vectorIndex);
        const values = removeElements(row.values, vectorIndex);
        
        return { ...row, periods, values };
      }),
    };
  } else {
    return {
      ...mashup,
      datasets: [],
      vectors: [],
      rows: []
    }
  }
};

export default removeMashupVector;
