import {
  collection,
  doc,
  setDoc,
  deleteDoc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

import identify from "../../../_common/lib/identify";
import { firestore } from "../config";
import clog from "../../../_common/lib/debug/clog";

// ?? is used to ensure we don't convert zeros to empty string!
const neutralise = (obj) =>
  JSON.parse(JSON.stringify(obj, (_, value) => value ?? ""));
const timestamp = () => Date.now();

// this is the new version which allows fields to be excluded and defaults undefined values to empty string
const prepare = (object, ...fields) => {
  return JSON.parse(
    JSON.stringify(object, (key, value) =>
      fields.includes(key) ? undefined : value ?? ""
    )
  );
};

const unpack = (snapshot) => {
  return snapshot.map((item) => {
    const data = item.data() || {};

    return {
      ...data
    };
  });
};

function FirestoreApi({ authenticatedUser: { uid } } = {}) {
  this.uid = uid;

  this.addDocument = ({ path, id = identify(), data, sid }) => {
    clog( sid, { path, id, data, sid });
    data._created = { uid, ts: timestamp() };
    data.id = id;

    const payload = neutralise(data);

    return new Promise(async (resolve, reject) => {
      try {
        const docRef = doc(firestore, `${path}/${id}`);
        await setDoc(docRef, payload, { merge: true });
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  };

  this.compoundQueryDocuments = ({ path, parameters = [] }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const criteria = query(
          collection(firestore, path),
          ...parameters.map(({ field, operator, value }) =>
            where(field, operator, value)
          )
        );
        const response = await getDocs(criteria);

        resolve(unpack(response.docs));
      } catch (error) {
        reject(error);
      }
    });
  };

  this.deleteDocument = ({ path, id, data, sid }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const trash = await this.addDocument({ path: `users/${uid}/trash`, data, sid });
        const docRef = doc(firestore, `${path}/${id}`);

        await deleteDoc(docRef);

        resolve(trash);
      } catch (error) {
        reject(error);
      }
    });
  };

  this.eraseDocument = ({ path }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const docRef = doc(firestore, path);
        await deleteDoc(docRef);

        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  };

  this.getDocument = ({ path, sid }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const docRef = doc(firestore, path);
        const response = await getDoc(docRef);
        const data = response.data();

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  };

  this.getDocuments = ({ path, sid }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const colRef = collection(firestore, path);
        const response = await getDocs(colRef);

        resolve(unpack(response.docs));
      } catch (error) {
        reject(error);
      }
    });
  };

  this.queryDocuments = ({ path, where: { field, operator = "==", value }, sid }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const criteria = query(
          collection(firestore, path),
          where(field, operator, value)
        );
        const response = await getDocs(criteria);

        resolve(unpack(response.docs));
      } catch (error) {
        reject(error);
      }
    });
  };

  this.queryUserDocuments = ({ path, uid, sid }) => {
    return this.queryDocuments({
      path,
      where: { field: "_acl", operator: "array-contains", value: uid },
      sid,
    });
  };

  this.subscribeDocs = ({ path, sid, hook }) => {
    return onSnapshot(collection(firestore, path), (snapshot) => {
      hook(unpack(snapshot.docs));
    });
  };

  this.subscribeQuery = ({ path, options, hook, sid }) => {
    const filters = Array.isArray(options)
      ? options.map(({ field, operator = "==", value }) => {
          return where(field, operator, value);
        })
      : [where(options.field, options.operator, options.value)];

    const criteria = query(collection(firestore, path), ...filters);

    return onSnapshot(criteria, (snapshot) => {
      hook(unpack(snapshot.docs));
    });
  };

  this.subscribeDocument = ({ path, id, sid, hook }) => {
    return onSnapshot(doc(firestore, path, id), (snapshot) => {
      const data = snapshot.data();
      hook(data);
    });
  };

  this.updateDocument = ({ path, id, data, sid }, debug) => {
    clog( sid, { path, id, data, sid });

    return new Promise(async (resolve, reject) => {
      try {
        const docRef = doc(firestore, `${path}/${id}`);
        data._updated = {
          uid,
          ts: timestamp(),
        };
        const payload = prepare(data);
        await setDoc(docRef, payload, { merge: true });

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export default FirestoreApi;
