import { useContext } from "react";

import { Button } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";

import AuthContext from "../../../_services/auth/AuthContext";
import LoggingContext from "../../../_services/logging/LoggingContext";

const GoogleLoginButton = ({ id, popup, onLogin = () => {} }) => {
  const { google } = useContext(AuthContext);
  const logger = useContext(LoggingContext);

  const onClick = () => {
    google
      .login({ redirect: !popup })
      .then(onLogin)
      .catch((error) => logger.error({ error, sid: "c5c519c3-8e94-4787-ab52-9c0400867883" }));
  };

  return (
    <Button
      {...{ onClick }}
      variant="contained"
      color="error"
      startIcon={<GoogleIcon />}
      id={id}
    >
      GOOGLE 
    </Button>
  );
};

export default GoogleLoginButton;
