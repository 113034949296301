import copyToClipboard from "../../../../_common/lib/copyToClipboard";

const copyMashupToClipboard = ({ rows = [], vectors = [] } = {}) => {
  const header = `Period\t${vectors
    .map(({ alias, description }) => alias || description)
    .join("\t")}\n`;

  const multipliers = vectors.map(({ multiplier = 0 }) => {
    return multiplier === 0 ? 1.0 : Number(multiplier);
  })

  const lines = rows
    .map(({ period, values = [] } = {}) => {
      return `${period}\t${values.map((value, index) => value * multipliers[index]).join("\t")}\n`;
    })
    .join("");

  copyToClipboard(`${header}${lines}`);
};

export default copyMashupToClipboard;

