import getZoomFilter from "./getZoomFilter/code";

const getDelimitedData = ({
  mashup = {},
  delimiter = "\t",
  wrapper = "",
}) => {
  const { rows = [], vectors = [] } = mashup;

  const zoomFilter = getZoomFilter({ mashup });

  const header = `${wrapper}Period${wrapper}${delimiter}${vectors
    .map(
      ({ alias, description }) => `${wrapper}${alias || description}${wrapper}`
    )
    .join(delimiter)}\n`;

  const multipliers = vectors.map(({ multiplier = 0 }) => {
    return multiplier === 0 ? 1.0 : Number(multiplier);
  });

  const lines = rows
    .filter(zoomFilter)
    .map(({ period, values = [] } = {}) => {
      return `${period}${delimiter}${values
        .map((value, index) => value * multipliers[index])
        .join(delimiter)}\n`;
    })
    .join("");

  return `${header}${lines}`;
};

export default getDelimitedData;
