const UserAvatar = ({ initials, photoURL }) => {
  return photoURL ? (
    <img className="inline object-cover w-12 h-12 rounded-full" src={photoURL} alt="User Profile"/>
    // <Avatar alt={moniker} src={photoURL} />
  ) : (
    <div className="flex flex-row w-12 h-12 rounded-full bg-primary-400 text-white text-xl justify-center items-center">{initials}</div>
  );
};

export default UserAvatar;
