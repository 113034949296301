import { useContext, useEffect, useState } from "react";
import MashupContext from "../../_services/factivated/mashups/api/MashupContext";

import PublicationContext from "../../_services/factivated/publication/PublicationContext";
import UserMashupsView from "./UserMashupsView";
import LoggingContext from "../../_services/logging/LoggingContext";
import clog from "../../_common/lib/debug/clog";

const UserMashupsPage = () => {
  const logger = useContext(LoggingContext);
  const { deletePublishedMashup, subscribePublished } =
    useContext(PublicationContext);
  const { deleteMashup, subscribeMashups } = useContext(MashupContext);

  const [publications, setPublications] = useState([]);
  const [mashups, setMashups] = useState([]);

  const onPublishedMashupDelete = (mashup = {}) => {
    clog("onPublishedMashupDelete", mashup);
    deletePublishedMashup({
      mashup,
    }).catch((error) =>
      logger.error({ error, sid: "efd463ed-db22-4237-8a8d-1a60f2a8b9a9" })
    );
  };

  const onDeletePrivateMashup = (mashup = {}) => {
    clog("onDeletePrivateMashup", mashup);
    deleteMashup({ mashup }).catch((error) =>
      logger.error({ error, sid: "23d9efce-a593-4b93-9fb9-b6c3f6e8389d" })
    );
  };

  useEffect(() => {
    subscribePublished({
      hook: setPublications,
    });

    subscribeMashups({
      hook: setMashups,
    });
  }, [subscribeMashups, subscribePublished]);

  return (
    <UserMashupsView
      {...{
        publications,
        mashups,
        actions: { onDeletePrivateMashup, onPublishedMashupDelete },
      }}
    />
  );
};

export default UserMashupsPage;
