const Statistic = ({ text = "!missing!", value = 0 }) => {
    return (
        <div className="w-full md:w-1/3 lg:w-1/4 px-4 mb-8 lg:mb-0">
        <h2 className="mb-2 text-4xl md:text-5xl font-bold text-cta-400 tracking-tighter">
          {value.toLocaleString()}
        </h2>
        <p className="text-lg md:text-xl text-coolGray-500 font-medium">
          {text}
        </p>
      </div>
    );
}

export default function HeadlineNumbers({ statistics = [] }) {
  return (
    <section
      className="py-7 xl:py-14 bg-zinc-50"
    >
      <div className="container px-4 mx-auto">
        <div className="flex flex-wrap justify-center text-center -mx-4">
            {
                statistics.map(({ text = "!missing!", value = 0}) => {
                    return <Statistic key={text} text={text} value={value} />;
                })
            }
        </div>
      </div>
    </section>
  );
}
