import { useState } from "react";

import AppHeaderBar from "../_app/components/menus/AppHeaderBar";
import AppIcon from "../_app/components/icons/AppIcon";
import FullPageContainer from "../_app/components/containers/FullPageContainer";
import Heading2 from "../_app/components/text/Heading2";
import NewTabLink from "../_app/components/links/NewTabLink";
import PageDebugInfo from "../_common/debug/PageDebugInfo";
import SearchInput from "../_app/components/search/SearchInput";
import DataSourceTable from "./DataSourceTable";

const problems = {
  quandl: `Access currently blocked. If you'd like this information please contact us`,
  fao: `We are currently having technical problems accessing this data`
}

const mapSources = (sources) => {
  return [...sources
    .filter(({ api = "" }) => !problems[api.toLowerCase()])
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((item) => {
      return { ...item, id: item.key };
    }),
    ...sources
    .filter(({ api = "" }) => problems[api.toLowerCase()])
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((item) => {
      return { ...item, id: item.key };
    }),
  ];
};


const DataSourceListView = ({ data: sources = [] }) => {
  const columns = [
    {
      field: "key",
      headerName: "Publisher ID",
      formatter: ({ api = "", key }) => {
        const pid = `@${key.toUpperCase()}`;
        const issue = problems[api.toLowerCase()];
        return (
          Boolean(issue) ?
          <div className="">{issue}</div> :
          <NewTabLink
            href={`/mashup/new?pid=${key}`}
            tip={`Start a new mashup for ${pid}`}
          >
            {pid}
          </NewTabLink>
        );
      },
    },
    { field: "name", headerName: "Name" },
  ];

  const [rows, setRows] = useState(mapSources(sources));

  const handleFilterChange = (filtered = []) => {
    setRows(mapSources(filtered));
  };

  return (
    <FullPageContainer>
      <AppHeaderBar />
      <div className="flex flex-col gap-6">
        <div className="flex flex-row gap-10 items-center">
          <div className="flex flex-row gap-2 items-center">
            <AppIcon iconKey="database" colour="danger" />
            <Heading2>Data Sources</Heading2>
          </div>
          <SearchInput
            data={sources}
            onChange={handleFilterChange}
            label="search sources"
            delay={0}
          />
        </div>
        <DataSourceTable {...{ rows, columns }} />
      </div>
      <PageDebugInfo
        sid="9c7a451e-b118-444a-8d0a-bf19a0e51da5"
        data={sources}
      />
    </FullPageContainer>
  );
};

export default DataSourceListView;
