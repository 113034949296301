import { getFunctions, httpsCallable } from "firebase/functions";

import clog from "../../_common/lib/debug/clog";
import countries from "../data/countries";
import { sources } from "../sources/getDataSources";
import uniquely from "../../_common/lib/arrays/uniquely";

// convert all the non-standard (subject) tags into a single array of key|value strings so we can get the unique key+value combinations
const getDatasetTags = ({ _source }) =>
  Object.entries(_source)
    .filter((kv) => /^tag_/.test(kv[0]) && kv[0] !== "tag_subject")
    .map((kv) => kv.join("|"));

const getTagDescription = ({ key, value }) => {

  if (key === "tag_country_iso3") return countries.find(({iso3_code}) => iso3_code === value)?.name;

  return undefined;

};

const getQueryTags = ({ hits = [] }) => {
  const tags = hits.reduce((result, hit) => {
    return [...result, ...getDatasetTags(hit)];
  }, []);

  return uniquely(tags).map((tag) => {
    const [key, value] = tag.split("|");

    return { key, value, id: tag, description: getTagDescription({ key, value }) };
  });
};

const doSearch = ({ criteria = "", filters = [], index = "", size = 50 }) => {
  const query = httpsCallable(getFunctions(), "search-query");
  clog("doSearch", { criteria, index, filters });

  return new Promise(async (resolve, reject) => {
    try {
      const words = criteria.split(/\s+/);

      // index can be included in search criteria using @{index}
      const indexWord = words.find((w) => w[0] === "@");
      const elasticIndex = indexWord
        ? `pub_${indexWord.slice(1).toLowerCase()}`
        : index;

      const search = {
        size,
        query: {
          bool: {
            must: words
              .filter((w) => w[0] !== "@")
              .map((w) => {
                return { match: { description: w } };
              }),
            filter: filters
              .filter(Boolean)
              .map(({ type = "tag_subject", key }) => {
                return {
                  term: {
                    [`${type}.keyword`]: key,
                  },
                };
              }),
          },
        },
        aggs: {
          tag_subjects: {
            terms: {
              field: "tag_subject.keyword",
            },
          },
          pubs: {
            terms: {
              field: "_index",
            },
          },
        },
      };

      clog("cee28b5c-6cc0-47a6-b60f-43ca900eb20a", JSON.stringify(search));

      const result = (await query({ index: elasticIndex, search })) || {};

      const {
        hits: { hits = [], total: { value: count } = {} } = {},
        aggregations: {
          tag_subjects: { buckets: tag_subjects = [] } = {},
          pubs: { buckets: pub_buckets = [] } = {},
        } = {},
      } = result.data || {};

      const result_tags = getQueryTags({ hits });

      resolve({
        count,
        hits: hits.map(({ _source }) => {
          return {
            ..._source,
            dataset_key: _source.key,
            publisher_name:
              sources.find(({ key }) => key === _source.publisher_key)?.name ||
              "???",
          };
        }),
        pub_buckets,
        result_tags,
        tag_subjects,
      });
    } catch (error) {
      reject(error);
    }
  });
};

export { doSearch };
