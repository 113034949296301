import getNumberValue from "./getNumberValue";
import mashupHasDataset from "./mashupHasDataset";

const addMashupVector = ({ mashup, dataset, vector, series }) => {
  const { datasets = [], vectors = [], rows = [] } = { ...mashup };
  const { vector_id } = vector;

  var vectorIndex = vectors.findIndex((v) => v.vector_id === vector_id);

  if (vectorIndex === -1) {
    vectorIndex = vectors.push(vector) - 1;
  }

  // the dataset has fields that we will need to retrieve the data again
  if (!mashupHasDataset({ mashup, dataset })) {
    datasets.push(dataset);
  }

  series.data.forEach((item) => {
    var rowIndex = rows.findIndex((record) => {
      // first try and match on the visual display period, then unix date
      // this is to help with sources like BOE who use end of period dates for quarters etc.
      return (
        record.period === item.period || record.unix_date === item.unix_date
      );
    });

    // TODO: deal with values array as well
    const value = getNumberValue(item.value);

    if (rowIndex === -1) {
      // no matching date found so add a new row
      const values = new Array(vectors.length);
      values[vectorIndex] = value;
      const periods = new Array(vectors.length);
      periods[vectorIndex] = item.period;

      var record = {
        period: item.period,
        values,
        periods,
        unix_date: item.unix_date,
      };

      rows.push(record);
    } else {
      rows[rowIndex].values[vectorIndex] = value;
      rows[rowIndex].periods[vectorIndex] = item.period;
    }
  });

  return {
    ...mashup,
    datasets,
    rows,
    vectors,
  };
};

export default addMashupVector;
