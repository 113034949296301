import dayjs from "dayjs";
import { getFunctions, httpsCallable } from "firebase/functions";
import clog from "../../../_common/lib/debug/clog";

const getFactivatedData = ({ dataset = {}, vector = {}, gcf = "" }) => {
  const fetch = httpsCallable(getFunctions(), gcf);

  const { dataset_key, ticker } = dataset;
  const { vector_id } = vector;

  clog("getFactivatedData_A", { dataset, vector });

  return new Promise(async (resolve, reject) => {
    try {
      const {
        status,
        message,
        matrix = {},
      } = (await fetch({ ticker, vector: vector.name, dataset }))?.data || {};

      if (status === 200) {
        clog("getFactivatedData_B", { matrix });
        return resolve({
          ...matrix,
          dataset_key,
          vector_id,
          data: matrix.data.map((item) => {
            return { ...item, unix_date: dayjs(item.period).valueOf() };
          }),
        });
      } else {
        throw new Error(message);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export default getFactivatedData;
