import { useContext } from "react";

import AuthContext from "../../../_services/auth/AuthContext";
import EmailLoginButton from "./EmailLoginButton";
import FullPageContainer from "../../../_app/components/containers/FullPageContainer";
import GithubLoginButton from "./GithubLoginButton";
import GoogleLoginButton from "./GoogleLoginButton";
import navigateTo from "../../../_common/lib/navigateTo";
import PageDebugInfo from "../../../_common/debug/PageDebugInfo";

const LoginPage = () => {
  const { authenticatedUser } = useContext(AuthContext);

  if (authenticatedUser) navigateTo("/");

  return (
    <FullPageContainer maxWidth="xs">
      <div className="flex flex-col gap-2"
        direction="column"
        spacing={1}
        justifyContent="center"
        alignItems="stretch"
        style={{ marginLeft: "40%", marginRight: "40%", marginTop: "5rem" }}
      >
        <div className="flex flex-row items-center justify-center gap-2">
          <div className="text-xl text-primary-600 dark:text-primary-300">
            Sign in to Factivated
          </div>
        </div>
        <GoogleLoginButton
          onLogin={() => navigateTo("/")}
          id="1f40d49e-8c70-49b7-852d-45aebdfdbd63"
        />
        <GithubLoginButton
          onLogin={() => navigateTo("/")}
          id="ccc31d80-11b1-4b30-813c-fa487d965518"
        />
        <EmailLoginButton
          onClick={() => navigateTo("/login/email")}
          id="602ccb2a-1bf2-45af-be73-84dfe5b2f770"
        />
      </div>
      <PageDebugInfo
        sid="bf29a93d-27ac-41fe-83db-9f7c7fa021de"
        data={authenticatedUser}
      />
    </FullPageContainer>
  );
};

export default LoginPage;
