import { useContext } from "react";

import MashupContext from "../_services/factivated/mashups/api/MashupContext";
import MashupController from "./MashupController";

const NewMashupPage = ({ query = {} }) => {
  const { createBlankMashup } = useContext(MashupContext);
  const { pid } = query;
  const mashup = createBlankMashup({ criteria: pid ? `@${pid}` : "" });

  return <MashupController mashup={mashup} pid={pid} />;
};

export default NewMashupPage;
