import { useState } from "react";

import FormTitle from "../ui/FormTitle";
import FormInputLabel from "../ui/FormInputLabel";
import FullPageContainer from "../../components/containers/FullPageContainer";

const SignUpView = ({ onSignUpClick = () => {}, onSignInClick = () => {} }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  
  const handleSubmit = () => {
    onSignUpClick({
      email, password
    })
  }

  return (
    <FullPageContainer>
      <div
        style={{
          backgroundImage: "url(/images/data.jpg)",
          backgroundSize: "cover",
        }}
        className="flex flex-col justify-center items-center h-screen"
      >
        <div className="bg-gradient-to-r from-zinc-600 via-white to-zinc-600 bg-clip-text p-4 text-5xl text-transparent mb-8">
          re:factivated
        </div>
        <div className="flex flex-col gap-2 max-w-lg p-4 rounded-xl shadow-lg bg-zinc-50 dark:bg-dark-500 dark:text-zinc-500 border dark:border-zinc-700 md:min-w-[500px]">
          <FormTitle title="Signup" />

          <div className="flex flex-col">
            <FormInputLabel title="Email" />
            <input
              type="text"
              className="grow mt-2 mb-4 rounded-md px-2 py-2 bg-zinc-200 dark:bg-zinc-300 border"
              onChange={({ target: { value = "" }}) => setEmail(value)}
              placeholder="someone@somewhere.com"
              value={email}
            />
            <FormInputLabel title="Password" />
            <input
              type="password"
              className="grow mt-2 rounded-md px-2 py-2 bg-zinc-200 dark:bg-zinc-300 border"
              onChange={({ target: { value = "" }}) => setPassword(value)}
              value={password}

            />
          </div>
          <div className="flex mt-6">
            <button
              onClick={handleSubmit}
              className="cursor-pointer bg-secondary-700 dark:bg-secondary-500 dark:text-zinc-900 text-zinc-50 rounded-md px-4 py-2 flex-grow hover:shadow-lg"
            >
              Sign Up and Get Factivated
            </button>
          </div>
          <div
            onClick={onSignInClick}
            className="mt-6 flex gap-2 text-zinc-500 dark:text-zinc-400"
          >
            Already have an account?{" "}
            <span className="text-sky-600 dark:text-sky-400 font-semibold cursor-pointer">
              Sign in here
            </span>
          </div>
        </div>
      </div>
    </FullPageContainer>
  );
};

export default SignUpView;
