import truthy from "../../../_common/lib/truthy";

const makeFilters = (text) => {
  // return an array of regex to allow AND queries
  if (!text) return [/\w+/i];

  const cleaned = text.replace(/[^a-z0-9@. ,-]/gi, "");

  // treat comma separated non-blank words as an OR query - only one regex needed for that
  if (/,/.test(cleaned))
    return [
      new RegExp(
        cleaned
          .split(/,/)
          .filter(truthy)
          .map((w) => w.trim())
          .join("|"),
        "i"
      ),
    ];

  // treat space separated non-blank words as an AND query
  return cleaned
    .split(/\s+/)
    .filter(truthy)
    .map((w) => new RegExp(w, "i"));
};

export default makeFilters;
