import defaultFormatter from "../_app/components/tables/defaultFormatter";

export default function DataSourceTable({ rows = [], columns = [] }) {
  const formatters = columns.map(
    ({ formatter }) => formatter || defaultFormatter
  );

  return (
    <table className="table table-auto">
      <thead className="border-b mb-6">
        <tr>
          {columns.map(({ headerName, field }) => {
            return (
              <th
                key={field}
                className="col-auto whitespace-nowrap text-left py-4 text-primary-500 bg-transparent dark:text-primary-300 dark:bg-zinc-800"
              >
                {/* <Tooltip title={tip}> */}
                {headerName}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className="max-h-full">
        {rows.map((row, r) => {
          return (
            <tr key={`R${r}`} className="border-b border-zinc-600">
              {columns.map((column, c) => {
                const content = formatters[c](row, column.field);
                return (
                  <td
                    key={`R${r}C${c}`}
                    className="text-left py-2 whitespace-nowrap text-zinc-700 dark:text-zinc-300 dark:bg-zinc-800"
                  >
                    {content}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
