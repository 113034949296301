import { useState } from "react";
import { debounce } from "debounce";

import makeFilters from "./makeFilters";
import applyFilters from "./applyFilters";
import AppIcon from "../icons/AppIcon";

const SearchInput = ({
  id,
  label = "Search",
  onChange = () => {},
  data = [],
  initialValue = "",
  delay = 250,
}) => {
  const [criteria, setCriteria] = useState(initialValue);

  const handleChange = debounce((value) => {
    setCriteria(value);
    onChange(data.filter((item) => applyFilters(item,  makeFilters(value))));
  }, delay);

  return (
    <div className="flex flex-row gap-2 items-center">
      <AppIcon iconKey="search" />
      <input
        className="bg-zinc-100 px-3 py-1 rounded-md md:w-72"
        id={id}
        defaultValue={criteria}
        type="text"
        placeholder={label}
        onChange={({ target: { value }}) => handleChange(value)}
      />
    </div>
  );

};

export default SearchInput;
