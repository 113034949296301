import { useContext, useEffect, useState } from "react";

import Loading from "../_app/components/loading/Loading";
import LoggingContext from "../_services/logging/LoggingContext";
import MashupContext from "../_services/factivated/mashups/api/MashupContext";
import MashupController from "./MashupController";

const EditMashupPage = ({ id, query }) => {
  const logger = useContext(LoggingContext);
  const { getMashupData } = useContext(MashupContext);

  const [mashup, setMashup] = useState();

  useEffect(() => {
    getMashupData({ id, sid: "0c89554c-73ce-41b3-bcc8-224a818c7bc1" })
      .then(setMashup)
      .catch((error) =>
        logger.error({ error, sid: "76e8bd8c-a828-432a-8a41-7145c11bfab5" })
      );
  }, [getMashupData, id, logger]);

  if (!mashup) return <Loading />;

  return <MashupController mashup={mashup} />;
};

export default EditMashupPage;
