import { cloneElement, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

const useQuery = () => {
  const { search } = useLocation();
 
  return useMemo(() => {
    const params = new URLSearchParams(search);
    const query = {};
  
    for (const [key, value] of params.entries()) {
      query[key] = value;
    }

    return query;

  }, [search]);
}

const Parameterised = ({ component }) => {
  const params = useParams() || {};
  const query = useQuery();

  return cloneElement(component, {...params, query });
};

export default Parameterised;
