export default function GetStartedCTA() {
  return (
    <section className="py-24 bg-white overflow-hidden">
      <div className="container px-4 mx-auto">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/2 px-4 mb-20 lg:mb-0">
            <div className="max-w-md mx-auto">
              <h2 className="mb-8 text-3xl md:text-5xl font-heading font-bold text-zinc-900 md:leading-15">
                Join the fastest growing community of open data users
              </h2>
              <ul className="mb-8">
                <li className="flex items-center mb-4">
                  <img
                    className="mr-3"
                    src="flex-ui-assets/elements/cta/checkbox-green.svg"
                    alt=""
                  />
                  <span className="text-lg md:text-xl font-heading text-zinc-500">
                    Mauris pellentesque congue libero nec
                  </span>
                </li>
                <li className="flex items-center mb-4">
                  <img
                    className="mr-3"
                    src="flex-ui-assets/elements/cta/checkbox-green.svg"
                    alt=""
                  />
                  <span className="text-lg md:text-xl font-heading text-zinc-500">
                    Suspendisse mollis tincidunt
                  </span>
                </li>
                <li className="flex items-center">
                  <img
                    className="mr-3"
                    src="flex-ui-assets/elements/cta/checkbox-green.svg"
                    alt=""
                  />
                  <span className="text-lg md:text-xl font-heading text-zinc-500">
                    Praesent varius justo vel justo pulvinar
                  </span>
                </li>
              </ul>
              <div className="flex flex-wrap items-center">
                <a
                  className="inline-flex items-center justify-center px-7 py-3 h-14 w-full mb-2 md:mb-0 md:mr-4 md:w-auto text-lg leading-7 text-zinc-800 hover:bg-zinc-100 font-medium focus:ring-2 border border-zinc-300 rounded-md shadow-sm"
                  href="/"
                >
                  Learn More
                </a>
                <a
                  className="inline-flex items-center justify-center px-7 py-3 h-14 w-full md:w-auto text-lg leading-7 text-white bg-cta-600 hover:bg-cta-700 font-medium focus:ring-2 focus:ring-green-500 focus:ring-opacity-50 border border-transparent rounded-md shadow-sm"
                  href="/mashup"
                >
                  Get Started
                </a>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-4">
            <div className="relative max-w-max mx-auto">
              <img
                className="absolute top-0 right-0 -mt-6 lg:-mt-12 -mr-6 lg:-mr-12 w-20 lg:w-auto z-10"
                src="flex-ui-assets/elements/circle3-yellow.svg"
                alt=""
              />
              <img
                className="absolute bottom-0 left-0 -mb-6 lg:-mb-10-ml-6 lg:-ml-12 w-20 lg:w-auto"
                src="flex-ui-assets/elements/dots3-blue.svg"
                alt=""
              />
              <img
                className="relative"
                src="/images/data.jpg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
