import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    // mode: 'dark',
    primary: {
      main: "#1769aa",
      light: "#2196f3"
    },
    secondary: {
      main: "#009688",
      light: "#33ab9f"
    },
  },
});

export default theme;