import React, { useContext } from "react";
import SignUpView from "./SignUpView";
import AuthContext from "../../../_services/auth/AuthContext";
import navigateTo from "../../../_common/lib/navigateTo";

const SignUpPage = () => {
  const {
    email: { signup = console.log },
  } = useContext(AuthContext);

  const handleSignUp = (credentials) => {
    signup(credentials)
      .then(() => navigateTo("/"))
      .catch(console.error);
  };

  const handleSignIn = () => {
    window.location = "/signin";
  };

  return (
    <SignUpView onSignUpClick={handleSignUp} onSignInClick={handleSignIn} />
  );
};

export default SignUpPage;
