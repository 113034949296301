import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { Button } from "@mui/material";

const EmailLoginButton = ({ id, onClick = () => {} }) => {
  return (
    <Button {...{ onClick }} variant="contained" color="success" startIcon={<AlternateEmailIcon />} id={id}>
      EMAIL
    </Button>
  );
};

export default EmailLoginButton;
