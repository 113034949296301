import { getAuth, signInWithPopup, signInWithRedirect } from "firebase/auth";

import { firebaseApp } from "../../firebase/config";

const auth = getAuth(firebaseApp);

const logInWithRedirect = ({ provider } = {}) => {
  return signInWithRedirect(auth, provider);
};

const logInWithPopup = ({ provider } = {}) => {
    return signInWithPopup(auth, provider);
  };
  
export {
    logInWithPopup, logInWithRedirect
};