export default function CtaLinkButton({ href = "#", children }) {
  return (
      <a
        className="flex flex-row items-center justify-center gap-3 px-4 py-1 bg-cta-600 hover:bg-cta-700 text-white dark:bg-cta-700 dark:hover:bg-cta-600 rounded-md cursor-pointer"
        href={href}
      >
        {children}
      </a>
  );
}
