const cleanRowValues = ({ rows = [] } = {}) => {
    return rows.map((row = {}) => {
      const { values = [], periods = [], period } = row;
  
      const definedPeriod = periods.find(date => date !== undefined) || period;
  
      return {
        ...row,
        values,
        period: definedPeriod,
      };
    });
  };
  
  export default cleanRowValues;
  