const colours = [
  "ef476f",
  "f78c6b",
  "ffd166",
  "83d483",
  "06d6a0",
  "0cb0a9",
  "118ab2",
  "073b4c",
].map((item) => `#${item}`);

const length = colours.length;

// just reuse the colours if we run out ...
const getLineColour = (index) => colours[index % length];

export default getLineColour;
