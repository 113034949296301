const getChartDimensions = ({ width, height }) => {
    // TODO: see if this can be done with media queries / css
    if (width >= 1536) return {
      width: width * .95, height: Math.round(height * 0.7)
    };
    if (width >= 1200) return {
      width: 1100, height: Math.round(height * 0.7)
    };
    if (width >= 900) return {
      width: 800, height: Math.round(height * 0.7)
    };
    // if (width >= 600) return {
    //   width: 500, height: Math.round(height * 0.7)
    // };
  
    return {
      width: width * 0.8, height: height * 0.7
    }
  }

  export default getChartDimensions;