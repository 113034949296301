import { useState } from "react";

import MultiRangeSlider from "multi-range-slider-react";

import "../mashup/tools/slider.css";
import FullPageContainer from "../_app/components/containers/FullPageContainer";

const days = Array.from(
  "ABCDEFGHIJKLMNOPQRSTUVWXYZABCDEFGHIJKLMNOPQRSTUVWXYZABCDEFGHIJKLMNOPQRSTUVWXYZABCDEFGHIJKLMNOPQRSTUVWXYZABCDEFGHIJKLMNOPQRSTUVWXYZABCDEFGHIJKLMNOPQRSTUVWXYZABCDEFGHIJKLMNOPQRSTUVWXYZABCDEFGHIJKLMNOPQRSTUVWXYZABCDEFGHIJKLMNOPQRSTUVWXYZABCDEFGHIJKLMNOPQRSTUVWXYZ"
);

export default function Playpen() {
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(days.length);
  const [selection, setSelection] = useState(undefined);

  console.log("Playpen");

  const handleChange = (range = {}) => {
    setMin(range.minValue);
    setMax(range.maxValue);
    // setSelection(undefined);
  };

  const handleChangeCommitted = () => {
    setSelection(max - min);
  };

  return (
    <FullPageContainer>
      <div className="flex flex-col gap-6 mt-10 mx-10">
        <MultiRangeSlider
          baseClassName="fa32ced4-range-slider"
          min={0}
          max={days.length - 1}
          minValue={10}
          maxValue={200}
          onInput={handleChange}
          onChange={handleChangeCommitted}
          label={false}
          minCaption=""
          ruler={false}
          barInnerColor="#ACA19A"
          style={{
            border: "none",
            boxShadow: "none"
          }}
        />
        <div className="text-3xl text-zinc-700">
          Min <span className="text-cta-600">{min}</span> Max{" "}
          <span className="text-cta-600">{max}</span>
        </div>
        {Boolean(selection) && (
          <div className="text-3xl text-cta-600">{selection}</div>
        )}
      </div>
    </FullPageContainer>
  );
}
