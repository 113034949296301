import { useState } from "react";

import JSONPretty from "react-json-pretty";

import AppHeaderBar from "../_app/components/menus/AppHeaderBar";
import ChartView from "../charts/ChartView";
import CopyButton from "../_app/components/buttons/CopyButton";
import DatasetTable from "../mashup/datasets/DatasetTable";
import FullPageContainer from "../_app/components/containers/FullPageContainer";
import getDelimitedData from "../_services/factivated/mashups/lib/getDelimitedData";
import MashupLinks from "./MashupLinks";
import PageDebugInfo from "../_common/debug/PageDebugInfo";
import WaitingSpinner from "../_app/components/feedback/WaitingSpinner";
import getZoomFilter from "../_services/factivated/mashups/lib/getZoomFilter/code";
import MashupTitle from "./MashupTitle";
import ZoomSlider from "../mashup/tools/ZoomSlider";

const DatasetView = ({ mashup = {}, actions = {} }) => {
  const [view, setView] = useState(mashup.view || "table");

  const { onChange } = actions;

  const handleLinkClick = ({ format }) => {
    setView(format);
  };

  if (!mashup) return <WaitingSpinner />;

  const zoomFilter = getZoomFilter({ mashup });

  const json = {
    id: mashup.id,
    title: mashup.title,
    datasets: mashup.datasets,
    vectors: mashup.vectors,
    rows: mashup.rows
      ?.filter(zoomFilter)
      .map(({ values, period, unix_date: unix_dt }) => {
        return { period, unix_dt, values };
      }),
  };

  return (
    <FullPageContainer>
      <AppHeaderBar />
      <div className="flex flex-col gap-2">
        <MashupLinks mashup={mashup} onClick={handleLinkClick} />
        {view === "table" && (
          <div className="flex flex-col gap-2 mt-4">
            <MashupTitle mashup={mashup} />
            <DatasetTable mashup={mashup} actions={actions} />
          </div>
        )}
        {view === "json" && (
          <div className="flex flex-col gap-2 mt-4">
            <div  className="flex flex-row gap-4 items-center">
              <CopyButton content={json} />
              <MashupTitle mashup={mashup} style={{ marginBottom: "0" }} />
            </div>
            <ZoomSlider
              mashup={mashup}
              onChange={(zoom) => onChange({ zoom })}
              valueLabelDisplay="off"
            />
            <JSONPretty data={json} className="dark:text-zinc-50" />
          </div>
        )}
        {view === "csv" && (
          <div className="flex flex-col gap-2 mt-4">
            <div className="flex flex-row gap-4 items-center">
              <CopyButton content={getDelimitedData({ mashup, delimiter: ",", wrapper: '"' })} />
              <MashupTitle mashup={mashup} style={{ marginBottom: "0" }} />
            </div>
            <ZoomSlider
              mashup={mashup}
              onChange={(zoom) => onChange({ zoom })}
            />
            <div className="text-sm font-mono dark:text-zinc-50">
            {getDelimitedData({ mashup, delimiter: ",", wrapper: '"' })
              .split("\n")
              .map((line, ln) => {
                return (
                  <div key={`ln${ln}`} >
                    {line}
                  </div>
                );
              })}
              </div>
          </div>
        )}
        {view === "chart" && <ChartView mashup={mashup} actions={actions} />}
      </div>
      <PageDebugInfo sid="fcd8636c-9051-475c-b5d2-541b04ebd965" data={mashup} />
    </FullPageContainer>
  );
};

export default DatasetView;
