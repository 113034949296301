import { cloneElement, useContext, useMemo, useState } from "react";

import LoggingContext from "../../_services/logging/LoggingContext";
import WaitingSpinner from "../../_app/components/feedback/WaitingSpinner";

const QueryDataProvider = ({ query, view, waiting = <WaitingSpinner />, sid }) => {
  const logger = useContext(LoggingContext);
  const [result, setResult] = useState();

  useMemo(() => {
    query()
      .then(data => setResult({ data }))
      .catch(error => logger.error({ error, sid }));
  }, [query, logger, sid]);

  if (!result) return waiting;

  return cloneElement(view, { ...result });
};

export default QueryDataProvider;
