import convertDataset from "../../_services/factivated/datasets/convertDataset";
import convertVector from "../../_services/factivated/datasets/convertVector";

const DatasetResult = ({ dataset = {}, onClick = console.log }) => {

  const {
    description = "",
    series = [],
    publisher_key = "",
    publisher_name = "",
  } = dataset;

  return (
    <div>
      {series?.map((item = {}) => {

        const publishedBy = `Published by @${publisher_key} / ${publisher_name}`;

        return (
          <div
            className="flex flex-col items-start gap-2 px-3 py-2 text-md rounded-md bg-primary-400 text-white text-lg cursor-pointer hover:bg-cta-500"
            key={item.name}
            onClick={() =>
              onClick({
                dataset: convertDataset(dataset),
                vector: convertVector(dataset, item),
              })
            }
          >
            <div
            >
              {description}
            </div>
            <div className="text-sm text-zinc-200">{publishedBy}</div>
          </div>
        );
      })}
    </div>
  );
};

export default DatasetResult;
