import { useContext } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

import CacheContext from "../../_services/cache/CacheContext";
import clog from "../../_common/lib/debug/clog";

const SelectedTag = ({ text, onClick }) => {
  return (
    <div
      className="flex gap-2 items-center text-sm border border-transparent bg-primary-400 text-white rounded-md px-2 py-1.5 hover:font-bold hover:bg-primary-300 cursor-pointer"
      onClick={onClick}
    >
      {text.toUpperCase()}
      <FontAwesomeIcon icon={faCircleXmark} className="ml-2 text-lg" />
    </div>
  );
};

const UnselectedTag = ({ text, onClick }) => {
  return (
    <div
      className="text-sm border bg-zinc-300 rounded-md px-2 py-1.5 dark:bg-zinc-600 dark:hover:bg-zinc-500 dark:text-zinc-100 border-transparent hover:bg-zinc-200 cursor-pointer"
      onClick={onClick}
    >
      {text.toUpperCase()}
    </div>
  );
};

const FilterTags = ({ tag_subjects = [], actions = {} }) => {
  const { subjectFilter } = useContext(CacheContext) || {};
  const { onSubjectFilterClick: onClick } = actions;

  clog("subjectFilterClick", { filter: subjectFilter, tag_subjects });

  // no point showing filters if there's only one (or none!)
  if (tag_subjects.length <= 1 && !subjectFilter) return <></>;

  return (
    <div className="flex flex-row flex-wrap gap-2">
      {tag_subjects
        .sort((a, b) => a.key.localeCompare(b.key))
        .map(({ key }) => {
          const selected = key === subjectFilter?.key;
          return selected ? (
            <SelectedTag
              key={key}
              text={key}
              onClick={() => onClick({ type: "tag_subject", key })}
            />
          ) : (
            <UnselectedTag
              key={key}
              text={key}
              onClick={() => onClick({ type: "tag_subject", key })}
            />
          );
          // <div
          //   key={key}
          //   onClick={() => onClick({ type: "tag_subject", key })}
          //   onDelete={selected ?() => onClick({ type: "tag_subject", key }) : undefined}
          //   label={key.toUpperCase()}
          //   color={selected ? "primary" : "default"}
          //   clickable
          //   size={selected ? "large" : "small"}
          // >{key.toUpperCase()}</div>
        })}
    </div>
  );
};

export default FilterTags;
