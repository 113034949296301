import PrivateMashupItem from "./PrivateMashupItem";
import Heading2 from "../../../_app/components/text/Heading2";

const PrivateMashupList = ({ mashups = [], actions = {} }) => {
  return (
    <div className="flex flex-col gap-3">
      <Heading2>My Private Mashups</Heading2>
      <div className="flex flex-col gap-4">
        {mashups
          .sort((a, b) => b._created.ts - a._created.ts)
          .map((mashup = {}) => {
            const { id } = mashup;

            return (
              <PrivateMashupItem key={id} mashup={mashup} actions={actions} />
            );
          })}
      </div>
    </div>
  );
};

export default PrivateMashupList;
