import { useContext, useEffect, useState } from "react";

import Loading from "../../_app/components/loading/Loading";
import LoggingContext from "../../_services/logging/LoggingContext";
import MashupChartView from "../MashupChartView";
import MashupContext from "../../_services/factivated/mashups/api/MashupContext";

const PrivateMashupChartPage = ({ id }) => {
  const logger = useContext(LoggingContext);
  const { subscribeMashup } = useContext(MashupContext);

  const [mashupState, setMashupState] = useState();

  useEffect(() => {
    subscribeMashup({
      id,
      hook: setMashupState,
      sid: "a3a3ff7d-a3f7-4645-b3e2-34de5a9400e7",
    });
  }, [id, logger, subscribeMashup]);

  if (!mashupState) return <Loading />;

  return (
    <MashupChartView
      mashup={mashupState}
      actions={{
        onChange: (field) => {
          setMashupState({ ...mashupState, ...field });
        },
      }}
    />
  );
};
export default PrivateMashupChartPage;
