import { cloneElement } from "react";

import { useWindowSize } from "@uidotdev/usehooks";

import getChartData from "./lib/getChartData";
import getChartDimensions from "./lib/getChartDimensions";

const ChartContainer = ({ chart, mashup }) => {
  const data = getChartData(mashup);
  const size = useWindowSize();
  const { height } = getChartDimensions(size);

  return (
    <div
      className="w-full flex-grow"
      style={{ height: `${height}px` }}
    >
      {cloneElement(chart, { data, mashup })}
    </div>
  );
};

export default ChartContainer;
