export default function TextInput({
  autoComplete,
  id,
  name,
  onChange = () => {},
  onKeyDown = () => {},
  placeholder,
  type="text",
  value=""
}) {
  if (!id) throw new Error("id attribute is required 😡");

 return (
    <input
      id={id}
      autoComplete={autoComplete}
      className="block flex-1 border w-full rounded-md py-1.5 pl-3 text-gray-90 bg-zinc-200 placeholder:text-gray-400 focus:ring-0 text-lg sm:text-md sm:leading-6"
      name={name || id}
      onChange={({ target: { value: text } }) => onChange(text)}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      type={type}
      value={value}
    />
  );
}
