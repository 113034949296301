import { useContext } from "react";

import AuthContext from "../auth/AuthContext";
import DatabaseContext from "./DatabaseContext";
import FirestoreApi from "../firebase/firestore/FirestoreApi";

const DatabaseProvider = ({ children }) => {
  const { authenticatedUser = {} } = useContext(AuthContext) || {};
  const api = new FirestoreApi({ authenticatedUser });
  return <DatabaseContext.Provider value={api}>{children}</DatabaseContext.Provider>;
};

export default DatabaseProvider;
