import ChartView from "../../charts/ChartView";
import clog from "../../_common/lib/debug/clog";
import DatasetTable from "./DatasetTable";
import DatasetViewToolbar from "./DatasetViewToolbar";
import MashupSettings from "../MashupSettings";

const DatasetViewContainer = ({
  mashup,
  mashupActions,
}) => {

  clog("5e20ad34-a6c4-444e-9229-864654336d50", mashup);

  return (
    <div className="flex flex-col gap-2">
      <DatasetViewToolbar
        mashup={mashup}
        actions={mashupActions}
        view={mashup.view}
      />
      {mashup.view === "table" && (
        <DatasetTable
          mashup={mashup}
          actions={mashupActions}
        />
      )}
      {mashup.view === "settings" && (
        <MashupSettings
          mashup={mashup}
          actions={mashupActions}
        />
      )}
      {mashup.view === "chart" && (
        <ChartView
          mashup={mashup}
          hideTitle={true}
          actions={mashupActions}
        />
      )}
    </div>
  );
};

export default DatasetViewContainer;
