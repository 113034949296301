import { useContext, useState } from "react";
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";

import { Alert, Button, TextField } from "@mui/material";

import AuthContext from "../../../_services/auth/AuthContext";
import FullPageContainer from "../../../_app/components/containers/FullPageContainer";
import LoggingContext from "../../../_services/logging/LoggingContext";
import PageDebugInfo from "../../../_common/debug/PageDebugInfo";
import navigateTo from "../../../_common/lib/navigateTo";

const EmailLinkPage = () => {
  const logger = useContext(LoggingContext);
  const { auth } = useContext(AuthContext);

  const [email, setEmail] = useState(window.localStorage.getItem("emailForSignIn") || "");
  const [problem, setProblem] = useState();

  const onClick = () => {
    // Confirm the link is a sign-in with email link.
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(auth, email, window.location.href)
        .then((result) => {
          logger.info({ result, sid: "1867b31c-7406-418d-890c-a11eba20cf78" });
          navigateTo("/");
          // Clear email from storage.
          // window.localStorage.removeItem("emailForSignIn");
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch((error) => {
          setProblem("There was a problem with the link");
          logger.error({ error, sid: "563ae3d8-bbd3-47e3-b2be-998df1b2bc72" });
        });
    }
  };

  return (
    <FullPageContainer maxWidth="xs">
      <div className="flex flex-col gap-2" spacing={2}>
        <TextField
          value={email}
          onChange={({ target: { value } }) => setEmail(value)}
          type="email"
          placeholder="someone@somewhere.com"
          label="Email"
          required
          id="3b6dcb95-b40c-482a-8a55-90d10f8d532a"
        />
        <Button onClick={onClick} variant="contained" color="warning" id="3f1fb8c0-c3d6-4475-a835-710d93b34358">
          COMPLETE SIGN IN
        </Button>
        {
            problem && (
                <Alert severity="error" variant="filled">{problem}</Alert>
            )
        }
      </div>
      <PageDebugInfo sid="68a6c470-d9ca-4aba-a313-db3fb6698387" />
    </FullPageContainer>
  );
};

const emailLinkPageUrl = "/login/link";

export default EmailLinkPage;

export {
    emailLinkPageUrl
}