import React from "react";

const FormButton = ({ onClick = () => alert("click!"), children }) => {
  return (
    <button onClick={onClick} className="px-4 py-2 flex flex-row items-center gap-2 border border-zinc-300 dark:border-zinc-700 rounded-lg font-semibold hover:bg-zinc-200/40 hover:shadow-md dark:bg-zinc-700 dark:text-zinc-400 dark:hover:bg-sky-800 transition-colors ease-in-out">
      {children}
    </button>
  );
};

export default FormButton;
