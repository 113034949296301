import { useContext } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";

import AuthContext from "../../../_services/auth/AuthContext";
import LoggingContext from "../../../_services/logging/LoggingContext";
import CtaButton from "../../components/buttons/CtaButton";

const SignOutButton = ({ id, onLogout = () => {} }) => {
  const logger = useContext(LoggingContext);
  const { logout = {} } = useContext(AuthContext) || {};

  const onClick = () => {
    logout()
      .then(onLogout())
      .catch((error) =>
        logger.error({ error, sid: "eb686e8a-8e12-4ef2-938b-091168403153" })
      );
  };

  return (
    <CtaButton onClick={onClick}>
      <span className="text-lg">sign out</span>
      <FontAwesomeIcon icon={faArrowRightFromBracket} />
    </CtaButton>
  );
};

export default SignOutButton;
