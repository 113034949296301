import AppIcon from "../icons/AppIcon";
import Tooltipper from "../Tooltipper";

const SimpleIconButton = ({ icon = "", colour, onClick, tip = "" }) => {

  return (
    <Tooltipper tip={tip}>
      <div className="cursor-pointer"
        onClick={onClick}
      >
        {<AppIcon iconKey={icon} colour={colour} />}
      </div>
    </Tooltipper>
  );
};

export default SimpleIconButton;
