import React from "react";
import AboutPage from "./AboutPage";

const LandingPage = () => {

    return <AboutPage />;

  // return (
  //   <div
  //     style={{
  //       backgroundImage: "url(/images/home.jpeg)",
  //       backgroundSize: "cover",
  //       backgroundBlendMode: "darken",
  //     }}
  //     className="h-screen bg-zinc-600"
  //   >
  //     <div className="flex flex-col justify-center gap-4 items-center h-screen">
  //       <div className="text-5xl text-zinc-100">
  //         data to enrich your online business
  //       </div>
  //       <div className="text-zinc-200 text-xl mt-4 max-w-5xl">
  //         Find the data you need with a simple Google style search.
  //         Automagically combine numbers from different sources. Create charts
  //         with a single click. Download your results as CSV files to use in
  //         Excel and other apps
  //       </div>
  //       <div className="flex flex-row gap-6 items-center">
  //       <div className="bg-sky-500 text-zinc-200 font-semibold hover:border cursor-pointer px-4 py-2 rounded-lg mt-4"><a href="/signin">Get started</a></div>
  //       <div className="text-zinc-200 border-zinc-200 hover:border-sky-500 border font-semibold cursor-pointer px-4 py-2 rounded-lg mt-4">Find out more</div>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default LandingPage;
