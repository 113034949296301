import { Chip } from "@mui/material";

import LineChartView from "./types/LineChartView";
import AreaChartView from "./types/AreaChartView";
import SimpleBarChartView from "./types/SimpleBarChartView";
import ZoomSlider from "../mashup/tools/ZoomSlider";
import MashupTitle from "../view/MashupTitle";

const ChartView = ({ mashup = {}, actions = {}, hideTitle }) => {
  const { chartType = "line" } = mashup;

  const { onChange } = actions;

  return (
    <div className="flex flex-col gap-2 items-center">
      <div className="flex flex-row items-center gap-2 ml-8">
        {!hideTitle && (
          <MashupTitle
            mashup={mashup}
            style={{ marginRight: "1rem", marginBottom: "0" }}
          />
        )}
        <Chip
          label="LINE"
          onClick={() => onChange({ chartType: "line" })}
          color={chartType === "line" ? "warning" : "primary"}
          size="small"
        />
        <Chip
          label="AREA"
          onClick={() => onChange({ chartType: "area" })}
          color={chartType === "area" ? "warning" : "primary"}
          size="small"
        />
        <Chip
          label="BAR"
          onClick={() => onChange({ chartType: "simple-bar" })}
          color={chartType === "simple-bar" ? "warning" : "primary"}
          size="small"
        />
      </div>
      <div className="w-full px-8">
        <ZoomSlider mashup={mashup} onChange={(zoom) => onChange({ zoom })} />
      </div>

      {chartType === "line" && <LineChartView mashup={mashup} />}
      {chartType === "area" && <AreaChartView mashup={mashup} />}
      {chartType === "simple-bar" && <SimpleBarChartView mashup={mashup} />}
    </div>
  );
};

export default ChartView;
