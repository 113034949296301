import {
  getAuth,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";

import { firebaseApp } from "../../firebase/config";

const auth = getAuth(firebaseApp);

const signup = ({ email, password } = {}) => {
  return createUserWithEmailAndPassword(auth, email.trim(), password.trim());
};

const login = ({ email, password }) => {
  return signInWithEmailAndPassword(auth, email.trim(), password.trim());
};

const requestPasswordReset = (email) => {
  return sendPasswordResetEmail(auth, email);
};

const api = {
    login,
    requestPasswordReset,
    signup,
}

export default api;