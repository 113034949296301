import AppIcon from "../../../_app/components/icons/AppIcon";
import copyToClipboard from "../../../_common/lib/copyToClipboard";
import Heading6 from "../../../_app/components/text/Heading6";
import MashupStorageButton from "./MashupStorageButton";
import NewTabLink from "../../../_app/components/links/NewTabLink";
import PublishMashupButton from "./PublishMashupButton";
import SimpleIconButton from "../../../_app/components/buttons/SimpleIconButton";
import SubHeading from "../../../_app/components/text/SubHeading";
import TextButtonLink from "../../../_app/components/buttons/TextButtonLink";
import TextButton from "../../../_app/components/buttons/TextButton";
import abbreviate from "../../../_common/lib/text/abbreviate";

const PrivateMashupItem = ({ mashup = {}, actions = {} }) => {
  const { id, title = "Untitled Mashup", vectors = [], manifest = {} } = mashup;
  const subtitle = vectors
    .map(({ alias, description } = {}) => alias || abbreviate({ text: description, maxWords: 10 }).text || "")
    .join(", ");

  const { onDeletePrivateMashup } = actions;

  return (
    <div className="flex flex-col gap-0">
      <div className="flex flex-row gap-2 items-center">
        <Heading6>{title}</Heading6>
        <NewTabLink href={`/mashup/${id}`} tip="Edit this mashup">
          <AppIcon iconKey="edit" />
        </NewTabLink>
        <PublishMashupButton mashup={mashup} />
        <MashupStorageButton mashup={mashup} wrapper='"' delimiter="," />
        <SimpleIconButton
          onClick={() => onDeletePrivateMashup(mashup)}
          tip="Delete this mashup"
          icon="trash"
          colour="danger"
        />
      </div>
      <SubHeading>{subtitle}</SubHeading>
      <div className="flex flex-row items-center gap-3 mt-2">
        {manifest.url && (
          <TextButtonLink text="Download CSV" href={manifest.url} />
        )}
        {manifest.url && (
          <TextButton
            text="Copy Link"
            onClick={() => copyToClipboard(manifest.url)}
          />
        )}
      </div>
    </div>
  );
};

export default PrivateMashupItem;
