import getMashupRows from "../../datasets/getMashupRows";
import removeFields from "../../../../_common/lib/removeFields";


const createBlankMashup = ({ criteria = "" } = {}) => {
  return {
    criteria,
    datasets: [],
    period: "Period",
    rows: [],
    title: "Untitled",
    vectors: [],
    view: "table",
  };
};

function MashupApi({
  addDocument,
  deleteDocument,
  getDocument,
  subscribeDocument,
  subscribeDocs,
  updateDocument,
  uid,
}) {
  const mashupPath = `users/${uid}/mashups`;

  this.getMashup = ({ id }) => {
    return getDocument({ path: `${mashupPath}/${id}` });
  };

  this.getMashupData = ({ id }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const manifest = await this.getMashup({ id });

        if (!manifest) throw new Error(`manifest ${id} not found`);

        if (!manifest.rows) {
          const rows = await getMashupRows({ manifest });
          resolve({ ...manifest, rows });
        } else {
          resolve(manifest);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  this.refreshMashupData = ({ mashup }) => {
    return new Promise(async (resolve, reject) => {
      try {
          const rows = await getMashupRows({ manifest: mashup });
          resolve({ ...mashup, rows });
      } catch (error) {
        reject(error);
      }
    });
  };

  this.saveMashup = ({ mashup }) => {
    const { id } = mashup;
    const data = removeFields(mashup, "dirty");

    return id
      ? updateDocument({ path: mashupPath, id, data })
      : addDocument({ path: mashupPath, data });
  };

  this.subscribeMashups = ({ hook, sid }) => {
    return subscribeDocs({ path: mashupPath, hook });
  };

  this.subscribeMashup = ({ id, hook }) => {
    return subscribeDocument({
      path: `users/${uid}/mashups`,
      id,
      hook,
    });
  };

  this.createBlankMashup = createBlankMashup;

  this.deleteMashup = ({ mashup = {} }) => {
    return new Promise(async (resolve, reject) => {
      try {
        const { id } = mashup;

        // no need to save all the rows and search results as they can easily be recreated
        const trash = await deleteDocument({
          path: `users/${uid}/mashups`,
          id,
          data: removeFields(mashup, "rows", "searchResults")
        });

        resolve(trash);
      } catch (error) {
        reject(error);
      }
    });
  };
}

export default MashupApi;

export {
  createBlankMashup
}
