const ResultTags = ({ tags = [], selected = [], actions = {} }) => {
  // 1 tag isn't much help when filtering results!
  if (tags.length <= 1) return <></>;
  const { onResultFilterClick: onClick } = actions;

  return (
    <div className="flex flex-wrap gap-2">
      {tags
        .sort((a, b) => a.value.localeCompare(b.value))
        .map((tag = {}) => {
          const { key, value, description } = tag;
          return (
            <div key={`${key}|${value}`} className="flex gap-2">
              <input
                className="accent-primary-500 dark:accent-primary-400"
                type="checkbox"
                onClick={() => onClick(tag)}
              />
              <span className="dark:text-zinc-100">{(description || value).toUpperCase()}</span>
            </div>
          );
        })}
    </div>
  );
};

export default ResultTags;
