import DataSourceContext from "./DataSourceContext";
import getDataSources from "./getDataSources";

const api = {
  getDataSources,
};

const DataSourceProvider = ({ children }) => {
  return (
    <DataSourceContext.Provider value={api}>
      {children}
    </DataSourceContext.Provider>
  );
};

export default DataSourceProvider;
