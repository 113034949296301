import { useContext } from "react";

import { Button } from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";

import AuthContext from "../../../_services/auth/AuthContext";
import LoggingContext from "../../../_services/logging/LoggingContext";

const GithubLoginButton = ({ id, popup, onLogin = () => {} }) => {
  const logger = useContext(LoggingContext);
  const { github } = useContext(AuthContext);

  const onClick = () => {
    github
      .login({ redirect: !popup })
      .then(onLogin)
      .catch((error) => logger.error({ error, sid: "fd349645-2b7b-429f-928e-012b0f0070e6" }));
  };

  return (
    <Button {...{ onClick }} variant="contained" color="primary" startIcon={<GitHubIcon />} id={id}>
      Github
    </Button>
  );
};

export default GithubLoginButton;
