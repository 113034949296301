const getInitials = ({ name, size = 2 }) => {
  const parts = name.split(" ");
  var initials = "";

//   console.log({ name, size, parts });

  if (parts.length === 0) return parts[0][0].toUpperCase();

  for (var i = 0; i < Math.min(parts.length, size); i++) {
    if (parts[i].length > 0 && parts[i] !== "") {
      initials += parts[i][0];
    }
  }
  return initials.toUpperCase();
};

export default getInitials;
