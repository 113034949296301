import getNumberValue from "./getNumberValue";

const createMashup = ({ dataset, vector, series }) => {
  
  return {
    datasets: [dataset],
    vectors: [vector],
    period: "Period",
    rows: series.data.map(({ period, unix_date, value }) => {
      return {
        period,
        unix_date,
        values: [getNumberValue(value)],
        periods: [period],
      };
    }),
  };
};

export default createMashup;