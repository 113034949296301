import { useContext } from "react";
import { useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePlus, faBlender, faDatabase } from "@fortawesome/free-solid-svg-icons";


import AuthContext from "../../../_services/auth/AuthContext";

const options = [
  {
    href: "/mashup/new",
    icon: <FontAwesomeIcon icon={faSquarePlus} className="text-lg"/>,
    title: "New Mashup",
    tip: "Create a brand new mashup",
    domains: ["home", "view", "mashups", "mashup", "sources"],
    authenticated: false,
    id: "4a1c9561-6173-41e2-8ce2-942d07833311",
    notifyClick: true,
  },
  {
    href: "/mashups",
    icon: <FontAwesomeIcon icon={faBlender} className="text-lg"/>,
    title: "My Mashups",
    tip: "Go to your personal mashups page",
    domains: ["home", "view", "mashup", "sources"],
    authenticated: true,
    id: "580d1204-a0f9-4ff8-beab-2cba954815d3",
  },
  {
    href: "/sources",
    icon: <FontAwesomeIcon icon={faDatabase} className="text-lg"/>,
    title: "Data Sources",
    tip: "A list of all the current Factivated data sources",
    domains: ["home", "view", "mashup", "mashups"],
    authenticated: false,
    id: "8095b6dd-8344-4124-88cb-438f32c40ae8",
  },
];

const ContextMenu = ({ onClick }) => {
  const { authenticatedUser } = useContext(AuthContext);
  const { pathname = "/" } = useLocation() || {};

  // menu options are based on the root element of the location path
  const key = pathname.split("/").filter(Boolean)[0] || "home";

  return (
    <div className="flex flex-row gap-4 items-center">
      {options
        .filter(
          ({ authenticated }) =>
            (authenticated && authenticatedUser) || !authenticated
        )
        .filter(({ domains }) => domains.includes(key))
        .map((option) => {
          const { icon = <></>, title, id } = option;
          return (
            <div
              className="flex flex-row gap-2 px-4 py-2 items-center text-dark-300 hover:bg-zinc-100 dark:text-dark-200 dark:hover:bg-zinc-600 dark:hover:text-zinc-50 cursor-pointer rounded-md"
              key={id}
            >
              {icon}
              <a href={option.href} className="text-xl font-medium " color="secondary">
                {title}
              </a>
            </div>
          );
        })}
    </div>
  );
};

export default ContextMenu;
