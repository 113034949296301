import LoggingContext from "./LoggingContext";

const logger = {
    error: console.error,
    log: console.log,
    info: console.info,
    warn: console.warn,
}

const LoggingProvider = ({ children }) => {
  return <LoggingContext.Provider value={logger}>{children}</LoggingContext.Provider>;
};

export default LoggingProvider;
