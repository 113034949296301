import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquarePollVertical } from "@fortawesome/free-solid-svg-icons";

export default function AppLogo() {
  return (
    <div className="flex flex-row items-center gap-2">
      <FontAwesomeIcon className="text-cta-600 dark:text-cta-700 text-4xl" icon={faSquarePollVertical} />
      <span className="text-4xl text-zinc-600 dark:text-zinc-300">factivated</span>
    </div>
  );
}
