export default function ChipButton({ label = "!label!", onClick = () => {} }) {
  return (
    <div
      className="text-sm rounded-xl text-zinc-50 bg-primary-500 hover:bg-primary-400 px-4 py-1 cursor-pointer"
      onClick={onClick}
    >
      {label}
    </div>
  );
}
