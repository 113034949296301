export default function InputLabel({ text, forElementId }) {
  return (
    <label
      htmlFor={forElementId}
      className="block text-sm font-medium leading-6 text-gray-900 dark:text-zinc-300"
    >
      {text}
    </label>
  );
}
