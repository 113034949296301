import { useContext } from "react";

import AuthContext from "../../../_services/auth/AuthContext";
import getAuthProviderData from "../../../_services/auth/getAuthProviderData";
import UserAvatar from "./UserAvatar";
import Tooltipper from "../../components/Tooltipper";

const UserAvatarGroup = () => {
  const { authenticatedUser } = useContext(AuthContext) || {};
  const { initials, moniker, photoURL } = getAuthProviderData({
    authenticatedUser,
  });

  return (
    <Tooltipper tip={moniker}>
        <UserAvatar {...{initials, photoURL }} />
    </Tooltipper>
  );
};

export default UserAvatarGroup;
