import CacheContext from "./CacheContext";

const CacheProvider = ({ children }) => {

  return (
    <CacheContext.Provider value={{}}>{children}</CacheContext.Provider>
  );
};

export default CacheProvider;
