import "./Loading.css";
const Loading = () => {
  return (
    <>
    <div className="loader">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
    </>
  );
};

export default Loading;
