import { useContext, useEffect, useState } from "react";

import DatasetView from "./DatasetView";
import LoggingContext from "../_services/logging/LoggingContext";
import PublicationContext from "../_services/factivated/publication/PublicationContext";
import CsvView from "./CsvView";

const DatasetPage = ({ id, query = {} }) => {
  const logger = useContext(LoggingContext);
  const { getMashupData } = useContext(PublicationContext);

  const format = query["format"] || "default";

  const [mashup, setMashup] = useState();

  const onChange = (field = {}) => {
    setMashup({ ...mashup, ...field });
  };

  useEffect(() => {
    getMashupData({ id, sid: "e89c2cc3-e2cc-4b97-8acd-0483751a6c3a" })
      .then(setMashup)
      .catch((error) =>
        logger.error({ error, sid: "a862e00b-a1e8-404e-a5ed-e23e72cc4a0e" })
      );
  }, [getMashupData, id, logger]);

  if (format === "csv") return <CsvView mashup={mashup} />;
  else return <DatasetView mashup={mashup} actions={{ onChange }} />;
};

export default DatasetPage;
