import { useContext } from "react";

import PublicationContext from "../../../_services/factivated/publication/PublicationContext";
import LoggingContext from "../../../_services/logging/LoggingContext";
import SimpleIconButton from "../../../_app/components/buttons/SimpleIconButton";

const PublishMashupButton = ({
  mashup,
  color = "primary",
  onPublish = () => {},
}) => {
  const logger = useContext(LoggingContext);
  const { publishMashup } = useContext(PublicationContext);

  const handleClick = () => {
    publishMashup({
      mashup,
      sid: "6328176b-4f74-42f7-b57d-bc3c6ad75ea0",
    })
      .then(onPublish)
      .catch((error) =>
        logger.error({ error, sid: "c1a07dc1-b660-43b9-895a-d89ec86058cb" })
      );
  };

  return (
    <SimpleIconButton
      icon="publish" 
      colour={color} 
      onClick={handleClick}
      tip="Publish this mashup 🚀"
    />
  );
};

export default PublishMashupButton;
