import { useRecoilState } from "recoil";

import clog from "../../_common/lib/debug/clog";
import copyMashupToClipboard from "../../_services/factivated/mashups/lib/copyMashupToClipboard";
import expandedRightPanelAtom from "../../_common/recoil/workspace/expandedRightPanelAtom";
import getServerName from "../../_common/lib/getServerName";
import SimpleIconButton from "../../_app/components/buttons/SimpleIconButton";

const DatasetViewToolbar = ({ mashup = {}, actions = {} }) => {
  const [expandedRightPanel, setExpandedRightPanel] = useRecoilState(
    expandedRightPanelAtom
  );
  const unsaved = !mashup.id || mashup.dirty;
  const { view = "table", title, sortOrder = 1 } = mashup;
  const showTitle = view !== "settings";
  const showStatus = view !== "settings" && unsaved;

  const { onChange, onClear, onPublish, onSave, onRefresh } = actions;

  if (mashup.vectors?.length === 0) return <></>;

  clog("DatasetViewToolbar", mashup);

  const handleCopy = () => {
    copyMashupToClipboard(mashup);
  };

  return (
    <div className="flex justify-between">
      {showTitle ? (
        <div
          className="text-xl text-primary-300 cursor-pointer"
          onClick={() => onChange({ view: "settings" })}
        >
          {title}
        </div>
      ) : (
        <div className="text-transparent">.</div>
      )}
      {showStatus && (
        <div
          className="border rounded-full px-4 bg-cta-600 text-white hover:bg-cta-500 dark:bg-transparent dark:text-cta-400 dark:border-cta-400 dark:hover:bg-zinc-600 cursor-pointer"
          onClick={onSave}
        >
          unsaved
        </div>
      )}
      <div className="flex flex-row gap-3 items-center">
        {!expandedRightPanel && (
          <SimpleIconButton
            icon="expand"
            onClick={() => setExpandedRightPanel(true)}
            tip="Expand view"
          />
        )}
        {expandedRightPanel && (
          <SimpleIconButton
            icon="collapse"
            onClick={() => setExpandedRightPanel(false)}
            tip="Collapse view "
          />
        )}
        {mashup.id && (
          <SimpleIconButton
            icon="refresh"
            onClick={onRefresh}
            tip="Refresh the mashup data"
          />
        )}
        {mashup.id && (
          <SimpleIconButton
            icon="chart-line"
            tip="Open chart in a new tab"
            onClick={() =>
              window.open(
                `${getServerName()}/mashup/private/chart/${mashup.id}`
              )
            }
          />
        )}
        {sortOrder === 1 && (
          <SimpleIconButton
            icon="arrow-up"
            tip="Sort by ascending period"
            onClick={() => onChange({ sortOrder: -1 })}
          />
        )}
        {sortOrder === -1 && (
          <SimpleIconButton
            icon="arrow-down"
            tip="Sort by descending period"
            onClick={() => onChange({ sortOrder: 1 })}
          />
        )}
        <SimpleIconButton
          icon="save"
          tip="Save this mashup"
          onClick={onSave}
        />
        <SimpleIconButton
          icon="share"
          tip="Share / Publish this mashup"
          onClick={onPublish}
        />
        <SimpleIconButton
          icon="copy"
          tip="Copy mashup data to the clipboard"
          onClick={handleCopy}
        />
        {view !== "table" && (
          <SimpleIconButton
            icon="grid"
            tip="Show the mashup table"
            onClick={() => onChange({ view: "table" })}
          />
        )}
        {view !== "settings" && (
          <SimpleIconButton
            icon="gear"
            tip="Show the mashup settings"
            onClick={() => onChange({ view: "settings" })}
            table
          />
        )}
        {view !== "chart" && (
          <SimpleIconButton
            icon="chart-line"
            tip="Quick chart view"
            onClick={() => onChange({ view: "chart" })}
          />
        )}
        <SimpleIconButton
          icon="eraser"
          tip="Clear the mashup and start again"
          onClick={onClear}
        />
      </div>
    </div>
  );
};

export default DatasetViewToolbar;
