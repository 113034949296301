import { useContext, useEffect, useState } from "react";
import { signal } from "@preact/signals";

import addMashupVector from "../_services/factivated/mashups/lib/addMashupVector";
import AuthContext from "../_services/auth/AuthContext";
import beautifyMashup from "../_services/factivated/mashups/lib/beautifyMashup";
import CacheContext from "../_services/cache/CacheContext";
import clog from "../_common/lib/debug/clog";
import DataSourceContext from "../_services/sources/DataSourceContext";
import { doSearch } from "../_services/search/SearchApi";
import getVectorData from "../_services/factivated/datasets/getVectorData";
import Loading from "../_app/components/loading/Loading";
import LoggingContext from "../_services/logging/LoggingContext";
import MashupContext from "../_services/factivated/mashups/api/MashupContext";
import mashupHasVector from "../_services/factivated/mashups/lib/mashupHasVector";
import MashupView from "./MashupView";
import navigateTo from "../_common/lib/navigateTo";
import PublicationContext from "../_services/factivated/publication/PublicationContext";
import removeMashupVector from "../_services/factivated/mashups/lib/removeMashupVector/code";

const busy = signal(false);

const MashupController = ({ mashup = {} }) => {
  const logger = useContext(LoggingContext);
  const { authenticatedUser } = useContext(AuthContext);
  const { getDataSources } = useContext(DataSourceContext);
  const { publishMashup } = useContext(PublicationContext);
  const { createBlankMashup, refreshMashupData, saveMashup } =
    useContext(MashupContext);
  const cache = useContext(CacheContext);

  const [mashupState, setMashupState] = useState(mashup);
  const [message, setMessage] = useState();
  const [sources, setSources] = useState();
  const [searchResults, setSearchResults] = useState(mashup.searchResults);
  const [index, setIndex] = useState();
  const [resultFilters, setResultFilters] = useState([]);

  useEffect(() => {
    getDataSources()
      .then(setSources)
      .catch((error) =>
        logger.error({ error, sid: "07933349-f56f-49d8-94e2-62b5a78a0669" })
      );

    const { criteria = "" } = mashup;

    if (criteria) {
      doSearch({ criteria })
        .then(setSearchResults)
        .catch((error) =>
          logger.error({ error, sid: "3b90bf33-5d55-4cfb-bef5-c59630f16505" })
        )
        .finally(() => (busy.value = false));
    }
  }, [getDataSources, logger, mashup]);

  if (!sources) return <Loading />;

  const handleAppMenuClick = (option) => {
    if (option.href === "/mashup/new") {
      setMashupState(createBlankMashup());
    }
  };

  const onVectorClick = ({ dataset, vector } = {}) => {
    if (mashupHasVector({ mashup: mashupState, vector })) {
      return onRemoveVector({ vector });
    }

    console.log({ vector, dataset });

    busy.value = true;
    getVectorData({ dataset, vector })
      .then((series) => {
        setMashupState({
          ...addMashupVector({
            mashup: mashupState,
            dataset,
            vector,
            series,
          }),
          dirty: true,
        });
      })
      .catch((error) => {
        setMessage({
          title: "Sorry!",
          subtitle: `The ${vector?.description} from ${dataset?.publisher?.name} is currently unavailable`,
        });
        logger.error({ error, sid: "e867ed69-0747-4f2b-ac0f-c7b5dad2134d" });
      })
      .finally(() => (busy.value = false));

    // doing this state update to force a refresh of the view and render the loading spinner
    setMashupState({ ...mashupState });
  };

  const onRemoveVector = ({ vector }) => {
    clog("cd826107-6ec2-4726-8340-190d1b1eac03", mashupState);

    setMashupState(
      beautifyMashup({
        mashup: {
          ...removeMashupVector({ mashup: mashupState, vector }),
          dirty: true,
        },
      })
    );
  };

  const onVectorEdit = (vector) => {
    setMashupState({
      ...mashupState,
      vectors: mashupState.vectors.map((item) =>
        item.vector_id === vector.vector_id ? vector : item
      ),
      dirty: true,
    });
  };

  const onCriteriaChange = (criteria = "") => {
    setMashupState({ ...mashupState, criteria });
    setResultFilters([]);
    cache.subjectFilter = undefined;
  };

  const onCriteriaSubmit = () => {
    busy.value = true;
    doSearch({ criteria: mashupState.criteria })
      .then(setSearchResults)
      .catch((error) =>
        logger.error({ error, sid: "e93091bd-c1a6-4b06-b198-8fcfa9611daa" })
      )
      .finally(() => (busy.value = false));
  };

  const onPublish = ({ frozen } = {}) => {
    if (!authenticatedUser) return navigateTo("/login");

    publishMashup({ mashup: mashupState, frozen })
      .then(({ id }) => {
        setMashupState({ ...mashupState, id });
      })
      .catch((error) =>
        logger.error({ error, sid: "aa55776f-1652-41a0-a088-fcc7220f8e14" })
      );
  };

  const onSave = () => {
    if (!authenticatedUser) return navigateTo("/login");

    saveMashup({
      mashup: {
        ...mashupState,
        searchResults,
      },
      sid: "7ccc2460-de68-4974-9979-e09928cb5a34",
    })
      .then((manifest) => {
        setMashupState({ ...manifest, rows: mashupState.rows });
      })
      .catch((error) =>
        logger.error({ error, sid: "87e26268-32a0-45e2-8621-860a5590c25b" })
      );
  };

  const handleMessageClose = () => setMessage(undefined);

  const onClear = () => {
    const { criteria = ""} = mashupState || {};
    setMashupState(createBlankMashup({ criteria }));
  };

  const onPublisherClick = (key) => {
    // toggle index selection on/off
    const updated = index === key ? undefined : key;
    const { criteria  } = mashupState;

    setIndex(updated);

    if (criteria) {
      busy.value = true;
      doSearch({ criteria, index: updated })
        .then(setSearchResults)
        .catch((error) =>
          logger.error({ error, sid: "fdbcf521-bbfc-4a45-b855-1f9575814491" })
        )
        .finally(() => (busy.value = false));

      setResultFilters([]);
      cache.subjectFilter = undefined;
    }
  };

  const onSubjectFilterClick = (filter = {}) => {
    // toggle filter on/off
    cache.subjectFilter =
      cache.subjectFilter?.key === filter.key ? undefined : filter;

    const { criteria  } = mashupState;


    if (criteria) {
      busy.value = true;
      doSearch({
        criteria,
        filters: cache.subjectFilter ? [cache.subjectFilter] : [],
        index,
      })
        .then(setSearchResults)
        .catch((error) =>
          logger.error({ error, sid: "fdbcf521-bbfc-4a45-b855-1f9575814491" })
        )
        .finally(() => (busy.value = false));

      // doing this state update to force a refresh of the view and render the loading spinner
      setMashupState({ ...mashupState });
    }
  };

  const onResultFilterClick = (filter) => {
    clog("handleResultFilterClick", filter);

    const exists = resultFilters.find(({ id }) => id === filter.id);

    if (exists) {
      // remove the tag if it's already selected
      setResultFilters(resultFilters.filter(({ id }) => id !== filter.id));
    } else {
      // add the new filter
      setResultFilters([...resultFilters, filter]);
    }
  };

  const onRefresh = () => {
    busy.value = true;
    refreshMashupData({ mashup: mashupState })
      .then(setMashupState)
      .catch((error) =>
        logger.error({ error, sid: "fae106a2-c07d-43b5-ba79-48c07569b524" })
      )
      .finally(() => {
        busy.value = false;
      });

    setMashupState({ ...mashupState });
  };

  const handleMashupChange = (field = {}) => {
    clog("handleMashupChange", field);
    setMashupState({ ...mashupState, ...field, dirty: true });
  };

  return (
    <MashupView
      busy={busy}
      filters={resultFilters}
      mashup={mashupState}
      mashupActions={{
        onChange: handleMashupChange,
        onClear,
        onPublish,
        onRefresh,
        onRemoveVector,
        onSave,
        onVectorClick,
        onVectorEdit,
      }}
      message={message}
      onAppMenuClick={handleAppMenuClick}
      onMessageClose={handleMessageClose}
      publisherIndex={index}
      searchActions={{
        onCriteriaChange,
        onCriteriaSubmit,
        onPublisherClick,
        onResultFilterClick,
        onSubjectFilterClick,
      }}
      searchResults={searchResults}
      sources={sources}
      user={authenticatedUser}
    />
  );
};

export default MashupController;
