import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import ChartContainer from "../ChartContainer";
import clog from "../../_common/lib/debug/clog";
import getLineColour from "../lib/getLineColour";

const LineChartContent = ({ mashup = {}, data = [] }) => {
  const { vectors = [] } = mashup;

  clog("LineChartContent", { mashup, data });

  return (
    <ResponsiveContainer>
      <LineChart
        data={data}
        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
        >
        {vectors.map((vector, index) => {
          return (
            <Line
            connectNulls
            dataKey={vector.vector_id}
            dot={false}
            key={vector.vector_id}
            name={vector.alias || vector.description}
            stroke={getLineColour(index)}
            strokeWidth={2}
            type="monotone"
            />
            );
          })}
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis dataKey="period" interval={20} />
        <YAxis />
        <Tooltip />
        <Legend />
      </LineChart>
    </ResponsiveContainer>
  );
};

const LineChartView = ({ id, mashup }) => {
  return (
    <ChartContainer id={id} chart={<LineChartContent />} mashup={mashup} />
  );
};

export default LineChartView;
