import AppIcon from "../../../_app/components/icons/AppIcon";
import Heading6 from "../../../_app/components/text/Heading6";
import NewTabLink from "../../../_app/components/links/NewTabLink";
import SimpleIconButton from "../../../_app/components/buttons/SimpleIconButton";
import SubHeading from "../../../_app/components/text/SubHeading";

const PublishedMashupItem = ({ mashup = {}, actions = {} }) => {
  const { id, title = "Untitled Mashup", vectors = [] } = mashup;
  const subtitle = vectors
    .map(({ alias, description } = {}) => alias || description || "")
    .join(", ");

  const { onPublishedMashupDelete } = actions;
  const onClick = () => onPublishedMashupDelete(mashup);

  return (
    <div className="flex flex-col gap-0">
      <div className="flex flex-row gap-3 items-center">
        <Heading6>{title}</Heading6>
        <NewTabLink
          href={`view/${id}`}
          tip="View your mashup as the world sees it 🌍"
        >
          <AppIcon iconKey="open-new-tab" />
        </NewTabLink>
        <SimpleIconButton
          onClick={onClick}
          tip="Permanently delete this mashup"
          icon="trash"
          colour="danger"
        />
      </div>
      <SubHeading>{subtitle}</SubHeading>
    </div>
  );
};

export default PublishedMashupItem;
