import { useContext, useState } from "react";

import AuthContext from "../../../_services/auth/AuthContext";
import LoggingContext from "../../../_services/logging/LoggingContext";
import LoginForm from "./LoginForm";
import UserMashupsPage from "../../../user/mashups/UserMashupsPage";
import ResetConfirmationPage from "../reset/ResetConfirmationPage";

const SigninPage = () => {
  const logger = useContext(LoggingContext);
  const {
    authenticatedUser,
    github,
    google,
    email: { login, requestPasswordReset },
  } = useContext(AuthContext);

  const [resetRequested, setResetRequested] = useState();

  if (authenticatedUser) return <UserMashupsPage />;

  const onGoogleLogin = () => {
    google
      .login({ redirect: true })
      .catch((error) =>
        logger.error({ error, sid: "91325467-b686-4a77-9fa8-38dc3ccc0a29" })
      );
  };

  const onGithubLogin = () => {
    github
      .login({ redirect: true })
      .catch((error) =>
        logger.error({ error, sid: "6422d480-6cd3-45eb-9b74-97e2d5915967" })
      );
  };

  const onForgotPasswordClick = (email) => {
    requestPasswordReset(email)
      .then(() => setResetRequested(email))
      .catch(console.error);
  };

  const onEmailSignIn = (credentials) => {
    login(credentials).then(console.log).catch(console.error);
  };

  return (
    <div
      style={{
        backgroundImage: "url(/images/data.jpg)",
        backgroundSize: "cover",
      }}
      className="flex flex-col justify-center items-center h-screen"
    >
      <div className="bg-gradient-to-r from-zinc-600 via-white to-zinc-600 bg-clip-text p-4 text-5xl text-transparent mb-8">
        re:factivated
      </div>
      {!resetRequested && (
        <LoginForm
          {...{ onEmailSignIn, onForgotPasswordClick, onGithubLogin, onGoogleLogin }}
        />
      )}
      {resetRequested && (
        <ResetConfirmationPage status={{ email: resetRequested }} />
      )}
    </div>
  );
};

export default SigninPage;
