import copyToClipboard from "../_common/lib/copyToClipboard";
import ChipButton from "../_app/components/buttons/ChipButton";

const MashupLinks = ({ mashup = {}, onClick = console.log }) => {
  const { id } = mashup;
  const csvHref = `https://us-central1-refactivated.cloudfunctions.net/mashup/?id=${id}&format=csv`;
  const jsonHref = `https://us-central1-refactivated.cloudfunctions.net/mashup/?id=${id}&format=json`;

    const handleClick = ({format, href}) => {
        copyToClipboard(href);
        onClick({ format, href });
    }

  return (
    <div className="flex flex-row gap-3 items-center justify-start"
    >
      <ChipButton
        label="CSV"
        onClick={() => handleClick({ format: 'csv', href: csvHref})}
      />
      <ChipButton
        label="JSON"
        onClick={() => handleClick({ format: 'json', href: jsonHref})}
      />
      <ChipButton
        label="TABLE"
        onClick={() => onClick({ format: 'table' })}
      />
      <ChipButton
        label="CHART"
        onClick={() => onClick({ format: 'chart' })}
      />
    </div>
  );
};

export default MashupLinks;
