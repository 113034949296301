
import getInitials from "../../_common/lib/getInitials";

const getAuthProviderData = ({
  authenticatedUser: { providerData = [] } = {},
} = {}) => {
  const { displayName, email, phoneNumber, photoURL, providerId } =
    providerData[0] || {};

  const moniker = displayName || email || "Anonymous";
  const initials = getInitials({ name: moniker });

  return {
    displayName,
    email,
    initials,
    moniker,
    phoneNumber,
    photoURL,
    providerId,
  };
};

export default getAuthProviderData;
