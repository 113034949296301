import Heading2 from "../../../_app/components/text/Heading2";
import PublishedMashupItem from "./PublishedMashupItem";

const PublishedMashupList = ({
  mashups = [],
  actions = {},
}) => {

  if (mashups.length === 0) return <></>;

  return (
    <div className="flex flex-col gap-3">

      <Heading2>My Published Mashups</Heading2>
      {mashups
        .sort((a, b) => b._created.ts - a._created.ts)
        .map((mashup = {}) => {
          const { id } = mashup;

          return <PublishedMashupItem mashup={mashup} actions={actions} key={id}  />
          
        })}
    </div>
  );
};

export default PublishedMashupList;
