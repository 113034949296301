import getVectorData from "./getVectorData";

const getVectorSeries = async ({ vector, datasets }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const dataset = datasets.find(
        ({ dataset_key }) => dataset_key === vector.dataset_key
      );
      const series = await getVectorData({ dataset, vector });

      resolve({ vector, series, dataset });
    } catch (error) {
      reject(error);
    }
  });
};

export default getVectorSeries;
